import {
    GET_USER_DATA,
    SET_HEADER_TOKEN,
    REMOVE_HEADER_TOKEN,
    SET_SPINNER,
    SET_TITLE
} from 'types/types';

const BaseReducer = (state, action) => {
    switch (action.type) {
        // Titulo en la AppBar
        case SET_TITLE:
            return {
                ...state,
                title: action.payload
            }

        case GET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            }

        case SET_HEADER_TOKEN:
            return {
                ...state,
                headers: {
                    ...state.headers,
                    'Authorization': `Bearer ${action.payload}`
                },
                headersFiles: {
                    'Authorization': `Bearer ${action.payload}`
                }
            }

        case REMOVE_HEADER_TOKEN:

            return {
                ...state,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ''
                },
                headersFiles: {
                    'Authorization': ''
                }
            }
            
        // case SET_HEADER_CONTENT:
        //
        //     return {
        //         ...state,
        //         headers: {
        //             ...state.headers,
        //             'Content-Type': 'application/json'
        //         }
        //     }

        // case REMOVE_HEADER_CONTENT:
        //     return {
        //         ...state,
        //         headers: action.payload
        //     }

        case SET_SPINNER:
            return {
                ...state,
                spinner: action.payload
            }

        default:
            return state
    }
}

export default BaseReducer