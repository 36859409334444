import React, { useEffect, useState, useContext  } from 'react';

// Material
import {Button, Grid, makeStyles, TextField} from '@material-ui/core';

// Context
import OperatorsContext from 'context/operators/operatorsContext';

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: 'column'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    boton: {
        marginTop: 15
    }
}));

const NewOperator = () => {

    const classes = useStyles()

    const operatorsContext = useContext(OperatorsContext);
    const { errors, addNewOperator, operatorsDeleteErrors } = operatorsContext

    const [ newOperator, setNewOperator ] = useState({
        email: '',
        password: '',
        name: '',
        description: ''
    })

    // Elimina errores al entrar (salir)
    useEffect( () => {
        operatorsDeleteErrors()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = ({target}) => {
        setNewOperator({
            ...newOperator,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
       
        addNewOperator(newOperator)
        // TODO: Redireccionar despues de crear usuario
        console.log('OPERATOR CREADO')
    }

    return (

        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{width: '100%'}}
        >
            <Grid item xs={12} sm={10} lg={8} >
                <form method="POST" action="" className={classes.form}>

                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        onChange={handleChange}
                        value={newOperator.email}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Email"*/}
                    {/*    name="email"*/}
                    {/*    value={newOperator.email}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.email) && <small className="red">{errors.email}</small> }

                    <TextField
                        name="password"
                        label="Password"
                        type="password"
                        onChange={handleChange}
                        value={newOperator.password}
                    />
                    {/*<input*/}
                    {/*    type="password"*/}
                    {/*    placeholder="Password"*/}
                    {/*    name="password"*/}
                    {/*    value={newOperator.password}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.password) && <small className="red">{errors.password}</small> }

                    <TextField
                        name="name"
                        label="Name"
                        onChange={handleChange}
                        value={newOperator.name}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Name"*/}
                    {/*    name="name"*/}
                    {/*    value={newOperator.name}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.name) && <small className="red">{errors.name}</small> }

                    <TextField
                        name="description"
                        label="Description"
                        onChange={handleChange}
                        value={newOperator.description}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Description"*/}
                    {/*    name="description"*/}
                    {/*    value={newOperator.description}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.boton}
                    >
                        Create
                    </Button>
                    {/*<input*/}
                    {/*    type="submit"*/}
                    {/*    value="Create"*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*/>*/}
                </form>
            </Grid>
        </Grid>

    )
}

export default NewOperator
