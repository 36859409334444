import React, {useContext, useEffect} from 'react';

// Materia
import {
    makeStyles,
    Grid

} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";

// Context
import BaseContext from "context/base/baseContext";
import OrdersContext from "context/orders/ordersContext";

// Custom Components
import TableOrdersAdmin from 'components/orders/table/TableOrdersAdmin';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },

}));

const DashboardAdminComponent = () => {

    // Classes
    const classes = useStyles()

    // Context
    const baseContext = useContext(BaseContext);
    const { setTitle } = baseContext;

    const ordersContext = useContext(OrdersContext)
    const { orders, getOrders } = ordersContext

    useEffect( () => {
        setTitle('Dashboard')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container 
            className={classes.root} 
            spacing={3} 
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12} sm={10} md={8}>
                <Grid container spacing={2} >
                    {
                        (!!orders && orders.length > 0)
                        ?
                            <TableOrdersAdmin itemData={orders} />
                        :
                            <Alert severity="warning" style={{width: '100%', justifyContent: 'center'}}>No orders to show</Alert>
                    }


                </Grid>
            </Grid>
        </Grid>
    )
}

export default DashboardAdminComponent