import React from 'react';

// Material
import { Grid, makeStyles } from "@material-ui/core";

// Luxon control de fechas
import { DateTime } from 'luxon';


const useStyles = makeStyles((theme) => ({
    base: {
        borderRadius: 10,
        marginBottom: 3,
        padding: 10
    },
    texto: {
        marginTop: 2,
        padding: '0 15px',
        fontSize: '1.2rem'
    }

}))
export default function Comment({commentData, userId}) {

    const classes = useStyles()


    const commentDate = DateTime.fromISO(commentData.created).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    // const align = (commentData.user_comment === userId) ? 'flex-start' : 'flex-end'
    const bgColor = (commentData.user_comment === userId) ? 'lightblue' : 'lightgreen'
    console.log(commentData)
    return (
        <Grid
            container
            justify="center"
        >
            <Grid item xs={12} >
                <div className={classes.base} style={{backgroundColor: bgColor}}>
                    <div><span style={{fontWeight: 'bold'}}>{commentData.user_data}</span> <small>{commentDate}</small></div>
                    <div className={classes.texto}>{commentData.comment_text}</div>
                </div>
            </Grid>

        </Grid>

    )
}
