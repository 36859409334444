import React, { useContext } from 'react';

// Material
import { Grid } from '@material-ui/core';

// Context
import BaseContext from 'context/base/baseContext';
import ReviewersContext from "context/reviewers/reviewersContext";

// CustomComponents
import FileReview from "./FileReview";
import Reviewers from "./Reviewers";


const ReviewersFileReview = () => {

    const baseContext = useContext(BaseContext)
    const { userData } = baseContext

    // Recuperamos el archivo para hacer review
    const reviewerContext = useContext(ReviewersContext)
    const { fileReview } = reviewerContext

    // const justifyFileReview = (!!fileReview.review_file) ? 'flex-start' : 'center'
    return (
        <Grid
            container
            direction="column"
            style={{margin: '20px 0'}}
        >
            {
                (userData.is_staff && !!fileReview.review_file) &&
                    // <Grid item xs={12} sm={6} className={classes.contai} >
                        <FileReview />
                    // </Grid>
            }

            {/*<Grid item xs={12} sm={6} >*/}
                <Reviewers />
            {/*</Grid>*/}
        </Grid>

    )
}

export default ReviewersFileReview
