import React, { useState, useContext, Fragment } from 'react';

// Routes
import {Link} from "react-router-dom";

// Material
import {
    ListItemIcon, ListItemText, makeStyles, List, Drawer, Tooltip,
} from "@material-ui/core";
import clsx from "clsx";

// Icons
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import PostAddIcon from '@material-ui/icons/PostAdd';
// Context
import BaseContext from 'context/base/baseContext';

// Custom Components
import BaseRoutes from "routes/BaseRoutes";

// Constants
import {DRAWER_ADMIN_MENU, DRAWER_CUSTOMER_MENU, ORDERS_MENU} from "../../types/menuConstants";
import {BASE_LINK, CREATE_ORDER_LINK} from "../../types/linkConstants";
import { COLOR_ORDER_STATUS } from "../../types/orderStatus";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    main: {
        width: '100%',
        minHeight: '85vh',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        marginTop: '65px',
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        marginTop: '65px',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        marginTop: '65px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        //padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const TracerDrawer = () => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    // Context
    const baseContext = useContext(BaseContext);
    const { userData } = baseContext

    // Comprobar si es un admin y si es un customer admin
    const isAdmin = (!!userData) && (userData.is_superuser)
    const isCustomerAdmin = (!!userData && !!userData.customer && (userData.customer_admin === 'True'))

    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.root}>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawer}>
                        {
                            open
                                ?
                                <Tooltip title="Close" placement="right" arrow>
                                    <ChevronLeftIcon />
                                </Tooltip>
                                :
                                <Tooltip title="Open" placement="right" arrow>
                                    <ChevronRightIcon />
                                </Tooltip>
                        }
                    </IconButton>
                </div>
                <Divider />

                <List >
                    <Link to={BASE_LINK}>
                        <ListItem button>
                            <Tooltip title="Dashboard" placement="right" arrow>
                                <ListItemIcon style={{fontSize: '125%'}}><DashboardIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </Link>
                    <Divider />

                    {
                        ORDERS_MENU.map( item => {
                            const itemColor = COLOR_ORDER_STATUS[item.itemSlug].color
                            return (
                                (item.itemSlug === 'new_order' && !!userData && !!userData.customer)
                                ?
                                    <Link to={CREATE_ORDER_LINK} key={item.itemSlug}>
                                        <ListItem button >
                                            <Tooltip title="Place New Order" placement="right" arrow>
                                                <ListItemIcon style={{fontSize: '125%', color: itemColor}}>
                                                    <PostAddIcon />
                                                </ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary="Place New Order" />
                                        </ListItem>
                                    </Link>

                                :
                                    <Link to={item.itemLink} key={item.itemSlug} >
                                        <ListItem button >
                                            <Tooltip title={item.itemName} placement="right" arrow>
                                                <ListItemIcon style={{fontSize: '125%', color: itemColor}}>
                                                    {item.itemIcon}
                                                </ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary={item.itemName} />
                                        </ListItem>
                                    </Link>
                            )
                        })
                    }

                    {
                        isAdmin &&
                        <>
                            <Divider />
                            {
                                DRAWER_ADMIN_MENU.map( item => (
                                    <Fragment key={item.itemSlug} >
                                        <Link to={item.itemLink} >
                                            <ListItem button >
                                                <Tooltip title={item.itemName} placement="right" arrow>
                                                    <ListItemIcon style={{fontSize: '125%'}}>{item.itemIcon}
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary={item.itemName} />
                                            </ListItem>
                                        </Link>
                                        {(item.itemSlug === 'list_users') && <Divider/>}
                                    </Fragment>
                                ))
                            }
                        </>
                    }

                    {
                        isCustomerAdmin &&
                        <>
                            <Divider />
                            {
                                DRAWER_CUSTOMER_MENU.map( item => (
                                    <Fragment key={item.itemSlug} >
                                        <Link to={item.itemLink} >
                                            <ListItem button >
                                                <Tooltip title={item.itemName} placement="right" arrow>
                                                    <ListItemIcon style={{fontSize: '125%'}}>{item.itemIcon}
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary={item.itemName} />
                                            </ListItem>
                                        </Link>
                                        {(item.itemSlug === 'list_users') && <Divider/>}
                                    </Fragment>
                                ))
                            }
                        </>
                    }

                </List>

            </Drawer>

            <main className={classes.main}>
                <BaseRoutes />
            </main>
        </div>
    )
}

export default TracerDrawer
