import React, { useContext, useReducer} from "react";

// Router
import { useHistory } from 'react-router-dom';

// Sweet Alert
import swal from "sweetalert";

// Reducer
import ReviewersReducer from "./reviewersReducer";

// Context
import ReviewersContext from "./reviewersContext";
import BaseContext from "context/base/baseContext";
import OrdersContext from "context/orders/ordersContext";

// Constants
import { API_REVIEWERS_FILE_REVIEWERS } from "types/apiConstants";
import { ACCEPT_REJECT, GET_REJECTED, GET_REVIEWERS, NEXT_REVIEWER } from "types/types";
import { ORDER_STATUS } from "types/orderStatus";
import { BASE_LINK } from "types/linkConstants";
import {EMAIL_TYPE} from "../../types/emailsTypes";
import apiGetData from "../../services/apiGetData";
import apiPatchData from "../../services/apiPatchData";


const ReviewersState = ( { children } ) => {

    const history = useHistory()

    const initialState = {
        reviewers: [],
        fileReview: {},
        next: {},
        rejected: {}
    }

    const [ state, dispatch ] = useReducer(ReviewersReducer, initialState)

    // Context
    const baseContext = useContext(BaseContext)
    const { headers, headersFiles, sendEmail } = baseContext

    const ordersContext = useContext(OrdersContext)
    const { orderAssigned, orderSelected } = ordersContext



    const getReviewers = async order_id => {
        const URL = `${API_REVIEWERS_FILE_REVIEWERS}?order_id=${order_id}`

        const data = await apiGetData(URL, headers)
        dispatch({
            type: GET_REVIEWERS,
            payload: data
        })

    }

    const getNextReviewer = () => {
        const next = state.reviewers.filter( item => item.pending )

        dispatch({
            type: NEXT_REVIEWER,
            payload: (next.length > 0) ? next[0] : {}
        })

    }

    const getRejected = () => {
        const rejected = state.reviewers.filter( item => !item.pending && !item.accepted)

        dispatch({
            type: GET_REJECTED,
            payload: (!rejected.length) ? {} : rejected[0]
        })
    }

    const acceptReject = async (reviewerdId, accepted, order_id, byTeam = false, bodyData, fileHeader = false ) => {
        // Id del reviewer
        const URL = `${API_REVIEWERS_FILE_REVIEWERS}${reviewerdId}/`
        // const body = {
        //     accepted: accepted,
        //     pending: false,
        //     notes: notes
        // }
        const header = (fileHeader) ? headersFiles : headers
        const data = await apiPatchData(URL, bodyData, header, true)
        if (data.id) {

            const reviewers = state.reviewers.filter( item => item.id !== data.id )
            dispatch({
                type: ACCEPT_REJECT,
                payload: [
                    ...reviewers,
                    data
                ]
            })


            if (!byTeam) getNextReviewer()
            if (accepted) {
                const nextReviewers = reviewers.filter( data => !!data.pending)
                const last =  nextReviewers.length === 0
                const extraData = {
                    order: orderSelected.description,
                    byTeam
                }

                if (last || byTeam) {

                    // Email de aviso al operador
                    sendEmail( EMAIL_TYPE.review_accepted, extraData,orderSelected.operator_name )

                    // Cambiar estado a Accepted.
                    orderAssigned(order_id, {order_status: ORDER_STATUS.approved})

                }else {
                    // Email al siguiente reiewer
                    sendEmail( EMAIL_TYPE.file_review, extraData, reviewers[0].reviewer_name )

                }


            }else {
                // Email al operador, rejected
                const reviewer = state.reviewers.filter( item => item.id === data.id )
                const extraData = {
                    order: orderSelected.description,
                    reviewer: reviewer[0].reviewer_name,
                    notes: data.notes
                }
                sendEmail( EMAIL_TYPE.review_rejected, extraData,orderSelected.operator_name )

                // Cambiar estado a In Proccess.
                orderAssigned(order_id, {order_status: ORDER_STATUS.in_process})
            }
            swal({
                title: 'File Review',
                text: `Review ${(accepted) ? 'accpted' : 'rejected'}`,
                icon: 'info'
            }).then( () => {

                history.push(BASE_LINK)
            } )

        }else {
            console.log('errores')
        }

    }

    return (
        <ReviewersContext.Provider
            value={{
                reviewers: state.reviewers,
                fileReview: state.fileReview,
                next: state.next,
                rejected: state.rejected,
                acceptReject,
                getNextReviewer,
                getRejected,
                getReviewers
            }}
        >
            {children}
        </ReviewersContext.Provider>
    )
}

export default ReviewersState
