// Material
import { Typography, Grid } from '@material-ui/core';

// Drag and Drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getListStyle = isDraggingOver => ({
    border: '1px solid #f44336',
    borderRadius: 5,
    background: isDraggingOver ? "#f7f0f0" : "transparent",
    padding: grid,
    width: 250
});

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid ,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#f4f6f6" : "transparent",
    border: isDragging ? '1px solid #f6ddcc' : '1px solid grey',
    borderRadius: 50,
    textAlign: 'center',

    // styles we need to apply on draggables
    ...draggableStyle
});

export default function CustomDD({state, setState}) {

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            // Si se suelta fuera del area, se elimina el elemento
            state.items.splice(result.source.index, 1)
            return;
        }

        const items = reorder(
            state.items,
            result.source.index,
            result.destination.index
        );

        setState({
            items
        });
    }

    return (
        <>
            {
                (!!state.items.length > 0) &&
                <Grid container justify="center">
                    <Grid item xs={12} style={{padding: 6, display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="h4" style={{borderBottom: '1px solid #f44336'}}>
                            Order reviewers by position (top = first)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{padding: 6, display: 'flex', justifyContent: 'center'}}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {state.items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>

            }
        </>

    )
}