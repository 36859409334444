import {
    LIST_OPERATORS,
    OPERATORS_ERRORS,
    OPERATORS_DELETE_ERRORS, DELETE_OPERATOR
} from 'types/types';

const OperatorsReducer = (state, action) => {
    switch (action.type) {
        case DELETE_OPERATOR:
        case LIST_OPERATORS:
            return {
                ...state,
                operators: action.payload
            }

        case OPERATORS_ERRORS:
            return {
                ...state,
                errors: action.payload
            }

        case OPERATORS_DELETE_ERRORS:
            return {
                ...state,
                errors: {}
            }

        default:
            return state
    }
}

export default OperatorsReducer