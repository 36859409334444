import React, {useContext, useEffect} from 'react';

// Material
import { Typography, makeStyles } from '@material-ui/core';

// Context
import ReviewersContext from 'context/reviewers/reviewersContext';

// Constants
import OrdersContext from "context/orders/ordersContext";
import { IN_PROCESS, REVIEW } from "types/orderStatus";
import ShowThumbs from "../shared/ShowThumbs";


const useStyles = makeStyles((theme) => ({
    container: {
        padding: 10,
        border: '1px solid green',
        borderRadius: 10,
        width: '100%',
        margin: '10px 0'
    }
}))

const FileReview = () => {

    const classes = useStyles()

    // Recuperamos el archivo para hacer review
    const reviewerContext = useContext(ReviewersContext)
    const {reviewers, fileReview, rejected, getRejected} = reviewerContext
    const {review_file, name, description} = fileReview

    // Recuperamos el context de orders
    const ordersContext = useContext(OrdersContext)
    const {orderSelected} = ordersContext
    const {status_name} = orderSelected

    useEffect(() => {
        getRejected()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewers])

    // Comprueba las páginas en las que se puede mostrar (según el estado)
    const showFileReview = (status_name === REVIEW || status_name === IN_PROCESS)

    return (
        <div className={classes.container}>
            {
                (showFileReview && !!review_file) &&
                    <>
                        <Typography variant="h3">File Reviewed</Typography>

                        <div style={{ marginTop: 10 }}>

                            <Typography variant="h4">
                                Name: <Typography variant="body2">{name}</Typography>
                            </Typography>
                            <Typography variant="h4">
                                Description: <Typography variant="body2">{description}</Typography>
                            </Typography>

                            {
                                (status_name === IN_PROCESS) &&

                                    <Typography variant="h4">
                                        Notes: <Typography variant="body2">{rejected.notes}</Typography>
                                    </Typography>
                            }
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <ShowThumbs image={fileReview} onlyThumb={true} />
                        </div>
                    </>
            }

        </div>

    )
}

export default FileReview
