import React, { useState, useEffect } from 'react';

// Material
import {
    makeStyles,
    TextField,
    Grid, Typography,
} from '@material-ui/core';

const useStyles = makeStyles( (theme) => ({
    line: {
        lineHeight: '4rem',
    },
    aire: {
        margin: '0 15px'
    }
}))

const InputWidthHeight = ( { item, selectedTypesData, setSelectedTypesData } ) => {

    // Style classes
    const classes = useStyles()

    const [ data, setData ] = useState({...item})

    useEffect( () => {
        setSelectedTypesData([
            ...selectedTypesData.filter( select => select.id !== data.id ),
            data
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleMeasureChange = ({ target }) => {
        setData({
            ...data,
            [target.name]: target.value
        })


    }

    return (
        <Grid item xs={12}>
            <Typography
                variant="body2"
                className={classes.line}
            >
                {item.title}:
            </Typography>

            <TextField
                name="width"
                label="Width"
                className={classes.aire}
                onChange={handleMeasureChange}
                value={data.width}
            />

            <TextField
                name="height" 
                label="Height"
                className={classes.aire}
                onChange={handleMeasureChange}
                value={data.height}
            />

            <Typography variant="body2">&nbsp;{item.measure_type}</Typography>

        </Grid>
    )
}

export default InputWidthHeight
