import React, {useContext, useReducer} from 'react';

import BaseContext from "context/base/baseContext";

import AuthContext from './authContext'
import AuthReducer from './authReducer'

import {
    API_LOGIN,
    API_TOKEN_VERIFY
} from 'types/apiConstants';

import {
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    LOGIN_TOKEN,
    LOGOUT,
    LOGIN_DELETE_ERRORS
} from 'types/types';
import apiPostData from "../../services/apiPostData";


const AuthState = props => {

    const baseContext = useContext(BaseContext)
    const { headers, spinner, setHeaderToken, setSpinner, removeHeaderToken } = baseContext

    const initialState = {
        token: localStorage.getItem('acTk'),
        errors: {},
        authenticated: false,
        user: null
    }

    const [ authState, dispatch ] = useReducer(AuthReducer, initialState)

    const checkToken = async () => {
        (!spinner) && setSpinner(true)

        if (authState.token !== null) {
            const body = {'token': authState.token}
            const tokenValid = await apiPostData(API_TOKEN_VERIFY, body, headers)
            if (!!tokenValid) {
                dispatch({
                    type: LOGIN_TOKEN
                })
                setHeaderToken(authState.token)
            }
            // fetch(API_TOKEN_VERIFY, {
            //     headers: headers,
            //     body: JSON.stringify({'token': authState.token}),
            //     method: 'POST'
            // })
            //     .then( response => response.json() )
            //     .then( tokenValid => {
            //
            //         if (!!tokenValid) {
            //             dispatch({
            //                 type: LOGIN_TOKEN
            //             })
            //             setHeaderToken(authState.token)
            //         }
            //     })
            //     .catch( e => console.log('checkToken ', e) )
        }else {
            dispatch({
                type: LOGIN_ERROR,
                payload: authState.token
            })
            setSpinner(false)
        }

    }

    const setToken = newToken => {

        if (!!newToken) {
            localStorage.setItem('acTk', newToken.access)
            localStorage.setItem('rfTk', newToken.refresh)
            setHeaderToken( newToken.access )

        }else {
            localStorage.removeItem('ac_tk')
            localStorage.removeItem('rf_tk')
            removeHeaderToken()
        }

    }

    const userLogin = async body => {
        const data = await apiPostData(API_LOGIN, body, headers)
        if (data.access) {
            dispatch({
                type: (data.access) ? LOGIN_EXITOSO : LOGIN_ERROR,
                payload: data
            })
            setToken( (data.access) ? data : null)
        }else if (data.detail) {
            // User/password no valid
            dispatch({
                type: LOGIN_ERROR,
                payload: {credentials: 'Username or Password invalid'}
            })
        }else {
            dispatch({
                type: LOGIN_ERROR,
                payload: data
            })
        }
        // fetch( API_LOGIN, {
        //     headers: headers,
        //     method: 'POST',
        //     body: JSON.stringify(body)
        // })
        //     .then( response => response.json())
        //     .then( data => {
        //         if (data.access) {
        //             dispatch({
        //                 type: (data.access) ? LOGIN_EXITOSO : LOGIN_ERROR,
        //                 payload: data
        //             })
        //             setToken( (data.access) ? data : null)
        //         }else if (data.detail) {
        //             // User/password no valid
        //             dispatch({
        //                 type: LOGIN_ERROR,
        //                 payload: {credentials: 'Username or Password invalid'}
        //             })
        //         }else {
        //             dispatch({
        //                 type: LOGIN_ERROR,
        //                 payload: data
        //             })
        //         }
        //
        //     })
        //     .catch( e => console.log(e))

    }
    const loginDeleteErrors = () => {
        dispatch({
            type: LOGIN_DELETE_ERRORS,
        })
    }

    const userLogout = () => {
        localStorage.removeItem('acTk')
        localStorage.removeItem('rfTk')
        setToken(null)
        removeHeaderToken()
        dispatch({
            type: LOGOUT,
            payload: {
                token: null,
                authenticated: false
            }
        })

    }

    return (
        <AuthContext.Provider
            value={{
                token: authState.token,
                errors: authState.errors,
                authenticated: authState.authenticated,
                loading: authState.loading,
                user: authState.user,
                checkToken,
                loginDeleteErrors,
                userLogin,
                userLogout
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
