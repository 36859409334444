import React from 'react';

// Custom Compornents
import Comment from "./Comment";

export default function LastComments({ orderComments, userId }) {
    return (
        <>
            <h3>Last Comments</h3>
            {
                (!!orderComments && orderComments.length > 0 )
                    ?
                    orderComments.map( data => <Comment key={data.key} commentData={data} userId={userId}/> )
                    : 'No comments'
            }
        </>
    )
}
