import { createRef, useState } from 'react';

// Material
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    Tooltip,
} from '@material-ui/core';

// ColorPicker
import {ColorPicker, createColor} from 'material-ui-color';

// Icons
import CropDinIcon from '@material-ui/icons/CropDin';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import GestureIcon from '@material-ui/icons/Gesture';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

// Konva
import {
    Layer,
    Stage
} from 'react-konva';

// Custom Shapes
import {
    addLine,
    NewRectangle,
    NewCircle,
    addTextNode,
    ImageShape
} from './shapes/';

import TextModal from "./components/TextModal";

export default function CustomCanvas({image, setUri, modifyImg, setModifyImg}) {

    const colors = {
        circle: 'orange',
        rectangle: 'blue',
        line: '#b7950b',
        text: 'red',
        clear: 'grey'
    }
    const buttonColor = '#0000ff';

    const stageEl = createRef()
    const layerEl = createRef()
    const layerBase = createRef()

    const [rectangles, setRectangles] = useState([]);
    const [circles, setCircles] = useState([]);
    const [selectedId, selectShape] = useState(null);
    const [shapes, setShapes] = useState([]);
    const [color, setColor] = useState(createColor(buttonColor))
    const [drawColor, setDrawColor] = useState(buttonColor)
    const [ text, setText ] = useState('')
    const [ textModal, setTextModal ] = useState(false)


    const getRandomInt = () => {
        const max = 500
        return 100 + Math.floor(Math.random() * Math.floor(max));
    }

    const drawLine = () => {
        addLine(stageEl.current.getStage(), layerEl.current, drawColor, 'brush');
    }

    const drawText = () => {

        if (!!text) {
            const xInit = getRandomInt()
            const yInit = getRandomInt()
            const id = addTextNode(stageEl.current.getStage(), layerEl.current, drawColor, text, xInit, yInit)
            const shs = shapes.concat([id]);
            setShapes(shs);
        }
        setText('')
        setTextModal(false)

    }

    const handleKeyDown = ({ keyCode }) => {
        if (keyCode === 13) {
            drawText()
        }
    }

    const newText = () => {
        setTextModal(true)
    }

    const handleClose = () => {
        setModifyImg(false)
    }

    const addRectangle = () => {

        const rect = {
            x: getRandomInt(),
            y: getRandomInt(),
            width: 100,
            height: 100,
            stroke: drawColor,
            id: `rect${rectangles.length + 1}`,
        };
        const rects = rectangles.concat([rect]);
        setRectangles(rects);
        const shs = shapes.concat([`rect${rectangles.length + 1}`]);
        setShapes(shs);
    };

    const addCircle = () => {

        const circ = {
            x: getRandomInt(),
            y: getRandomInt(),
            width: 100,
            height: 100,
            stroke: drawColor,
            id: `circ${circles.length + 1}`,
        };
        const circs = circles.concat([circ]);
        setCircles(circs);
        const shs = shapes.concat([`circ${circles.length + 1}`]);
        setShapes(shs);
    };

    const undo = () => {
        layerEl.current.clear()
        layerEl.current.destroyChildren()

    }

    const exportUrl = () => {
        setUri(stageEl.current.toDataURL())
        setModifyImg(false)
    }


    return (
        <Dialog fullScreen open={modifyImg} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Review Image</DialogTitle>
            <Grid container>
                <Grid item xs={12}>

                    <TextModal
                        text={text}
                        textModal={textModal}
                        handleKeyDown={handleKeyDown}
                        setText={setText}
                        setTextModal={setTextModal}
                    />

                    <Stage
                        style={{
                            margin: '1em',
                            border: '2px solid grey',
                            // backgroundImage: `url(${image})`,
                            // backgroundSize: 'contain',
                            // backgroundRepeat: 'no-repeat'
                        }}
                        width={window.innerWidth * 0.9}
                        height={window.innerHeight - 250}
                        ref={stageEl}
                        onMouseDown={e => {
                            // deselect when clicked on empty area
                            const clickedOnEmpty = e.target === e.target.getStage();

                            if (clickedOnEmpty) {
                                selectShape(null);
                            }
                        }}
                    >
                        <Layer ref={layerBase}>
                            <ImageShape imageUrl={image} />
                        </Layer>
                        <Layer ref={layerEl} >
                            {rectangles.map((rect, i) => {
                                return (

                                    <NewRectangle
                                        key={i}
                                        shapeProps={rect}
                                        isSelected={rect.id === selectedId}
                                        onSelect={() => {
                                            selectShape(rect.id);
                                        }}
                                        onChange={newAttrs => {
                                            const rects = rectangles.slice();
                                            rects[i] = newAttrs;
                                            setRectangles(rects);
                                        }}
                                    />
                                );
                            })}
                            {circles.map((circle, i) => {
                                return (
                                    <NewCircle
                                        key={i}
                                        shapeProps={circle}
                                        isSelected={circle.id === selectedId}
                                        onSelect={() => {
                                            selectShape(circle.id);
                                        }}
                                        onChange={newAttrs => {
                                            const circs = circles.slice();
                                            circs[i] = newAttrs;
                                            setCircles(circs);
                                        }}
                                    />
                                );
                            })}

                        </Layer>
                    </Stage>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="space-between">

                        <Grid
                            item xs={12}
                            sm={8}
                            style={{display: 'flex', marginLeft: 20, marginTop: 10}}>
                            <Button
                                variant="outlined"
                                style={{border: `1px solid ${colors.rectangle}`, color: drawColor}}
                                onClick={addRectangle}
                            >
                                <Tooltip title="Draw Square / Rectangle">
                                    <CropDinIcon />
                                </Tooltip>
                            </Button>
                            <Button
                                variant="outlined"
                                style={{border: `1px solid ${buttonColor}`, color: drawColor, marginLeft: 5}}
                                onClick={addCircle}
                            >
                                <Tooltip title="Draw Circle / Elipse">
                                    <RadioButtonUncheckedIcon />
                                </Tooltip>
                            </Button>
                            <Button
                                variant="outlined"
                                style={{border: `1px solid ${buttonColor}`, color: drawColor, marginLeft: 5}}
                                onClick={drawLine}
                            >
                                <Tooltip title="Free draw">
                                    <GestureIcon />
                                </Tooltip>
                            </Button>
                            <Button
                                variant="outlined"
                                style={{border: `1px solid ${buttonColor}`, color: drawColor, marginLeft: 5}}
                                onClick={newText}
                            >
                                <Tooltip title="Write text">
                                    <TextFieldsIcon />
                                </Tooltip>
                            </Button>

                            <Tooltip title="Select Color">
                                <div style={{marginLeft: 25}}>

                                    <ColorPicker
                                        defaultValue={color}
                                        value={color}
                                        hideTextfield={true}
                                        style={{marginLeft: 25}}
                                        onChange={value => {
                                            setColor(value)
                                            setDrawColor(value.css.backgroundColor)
                                        }}
                                    />

                                </div>
                            </Tooltip>

                            <Button
                                variant="outlined"
                                style={{
                                    border: `1px solid ${buttonColor}`,
                                    color: buttonColor,
                                    marginLeft: 25
                                }}
                                onClick={undo}
                            >
                                <Tooltip title="Clear all">
                                    <DeleteForeverOutlinedIcon/>
                                </Tooltip>
                            </Button>

                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginRight: 20,
                                marginTop: 10
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setModifyImg(false)}
                                style={{marginRight: 10}}
                            >
                                Cancel
                            </Button>
                            <Button variant="outlined" color="primary" onClick={exportUrl}>
                                Save and Send
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Dialog>
    )
}

