// Material
import {
    Grid,
    Typography,
    makeStyles
} from "@material-ui/core";

// Constants
import { API_URL } from "types/apiConstants";


const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}))


export default function ShowThumbs({ image, onlyThumb=false }) {

    const classes = useStyles()

    const url = image.review_file// (onlyThumb) ? image.img_change : image.review_file

    return (
        <Grid item xs={3}>
            <a
                href={`${API_URL}${url}`}
                className={classes.center}
                target="_blank"
                rel="noreferrer"
            >
                <img src={`${API_URL}${url}`} alt="Reviewed" width={100} />
                {
                    (!onlyThumb) &&
                        <>
                            <Typography variant="h6">Name: {image.name}</Typography>
                            <Typography variant="body2">Desc: {image.description}</Typography>
                        </>
                }

            </a>
        </Grid>
    )
}