import React, { useContext } from 'react';

// router
import { Link, useHistory } from 'react-router-dom';

// Icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// Material
import {AppBar, Avatar, Button, IconButton, Toolbar, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Context
import BaseContext from 'context/base/baseContext';
import AuthContext from 'context/auth/authContext';

// Constants
import {BASE_LINK} from 'types/linkConstants';
import {AVATAR_LOGO_IMAGE} from 'types/assetsTypes';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },

}));



const TracerAppBar = () => {

    // Estilos
    const classes = useStyles();

    // Context
    const baseContext = useContext(BaseContext)
    const { title, userData } = baseContext

    // TODO Cambiar
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { userLogout } = authContext

    const handleClick = e => {
        e.preventDefault()
        userLogout()
        history.push('/')
    }

    return (
        <div className={classes.root}>
            <AppBar color="transparent" position="absolute" >
                <Toolbar>

                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <Link to={BASE_LINK}>
                            <Avatar alt="Tracer Audiovisual logo" src={AVATAR_LOGO_IMAGE} />
                        </Link>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                        { (!!userData ) && userData.username}
                    </Typography>


                    <Button color="inherit" onClick={handleClick}>
                        {/*<MenuIcon />*/}
                        <ExitToAppIcon /> Logout
                    </Button>

                </Toolbar>
            </AppBar>
            <div className={classes.offset}></div>
        </div>
    )
}

export default TracerAppBar
