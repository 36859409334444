export const NEW_ORDER = 'new_order'
export const IN_PROCESS = 'process'
export const REVIEW = 'review'
export const APPROVED = 'approved'
export const SENT = 'sent'
export const ARCHIVE = 'archive'

export const ORDER_STATUS = {
    'new_order': 1,
    'in_process': 2,
    'ready_review': 3,
    'approved': 4,
    'sent': 5,
    'archive': 6
}

export const ORDER_STATUS_REVERSE = {
    1: 'new_order',
    2: 'in_process',
    3: 'ready_review',
    4: 'approved',
    5: 'sent',
    6: 'archive'
}

export const TITLE_ORDER_STATUS = {
    'new_order': 'New Orders',
    'new': 'New Orders',
    'in_process': 'Orders In Process',
    'process': 'In Process',
    'ready_review': 'Ready to Review',
    'review': 'Ready to Review',
    'approved': 'Orders Approved',
    'sent': 'Order Sent',
    'archive': 'Orders Archived'
}

export const COLOR_ORDER_STATUS = {
    'new_order': {
        color: '#f44336',
        borderColor: '#f44336'
    },
    'new': {
        color: '#f44336',
        borderColor: '#f44336'
    },
    'in_process': {
        color: '#ff9800',
        borderColor: '#ff9800'
    },
    'process': {
        color: '#ff9800',
        borderColor: '#ff9800'
    },
    'ready_review': {
        color: '#da70d6',
        borderColor: '#da70d6'
    },
    'review': {
        color: '#da70d6',
        borderColor: '#da70d6'
    },
    'approved': {
        color: '#4caf50',
        borderColor: '#4caf50'
    },
    'sent': {
        color: '#2196f3',
        borderColor: '#2196f3'
    },
    'archive': {
        color: '#808b96',
        borderColor: '#808b96'
    },
}

export const EMAIL_CHECK = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// export const EMAIL_CHECK = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/