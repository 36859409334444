import { createMuiTheme } from "@material-ui/core";
import {purple} from '@material-ui/core/colors';

export const theme = createMuiTheme({

})

export const tracerTheme = createMuiTheme({
    palette: {
        primary: {
            main: purple[500],
        },
        secondary: {
            main: '#f44336',
        },
    },
    typography: {
        h1: {
            fontWeight: 500,
            fontSize: '3rem',
        },
        h2: {
            fontWeight: 500,
            fontSize: '1.75rem',
        },
        h3: {
            fontWeight: 500,
            fontSize: '1.2rem',
        },
        h4: {
            fontWeight: 500,
            fontSize: '0.9rem',
        }
    },
    props: {
        MuiTypography: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h4',
                h6: 'h4',
                subtitle1: 'h2',
                subtitle2: 'h3',
                body1: 'p',
                body2: 'span',
            },
        },
    },
})