import React, { useReducer, useContext } from 'react';

// Router
import { useHistory } from 'react-router-dom';

// SweetAlert
import swal from "sweetalert";

// Context
import BaseContext from 'context/base/baseContext';
import CustomerContext from './customerContext';

// Reducer
import CustomerReducer from './customerReducer';

// Constants
import {
    LIST_CUSTOMERS,
    ADMINS_CUSTOMERS,
    NEW_CUSTOMER,
    CUSTOMER_DELETE_ERRORS,
    CUSTOMER_ERRORS, DELETE_CUSTOMER
} from 'types/types';
import { API_CUSTOMERS, API_CUSTOMERS_GET_ADMINS } from 'types/apiConstants';
import {ALERT_DELETE, ALERT_NEW_CUSTOMER} from "types/alertTypes";
import { BASE_LINK } from "types/linkConstants";
import apiGetData from "../../services/apiGetData";
import apiPostData from "../../services/apiPostData";
import apiDeleteData from "../../services/apiDeleteData";


// TODO LIST CUSTOMERS

const CustomerState = props => {

    // Estado inicial
    const initialState = {
        customers: [],
        errors: {}
    }

    // history
    const history = useHistory()

    // Context
    const baseContext = useContext(BaseContext);
    const { headers } = baseContext

    // Reducer
    const [ state, dispatch ] = useReducer(CustomerReducer, initialState)

    const getCustomers = async () => {
        const data = await apiGetData(API_CUSTOMERS, headers)
        dispatch({
            type: LIST_CUSTOMERS,
            payload: data
        })

    }

    const getCustomersAdmins = async () => {
        const data = await apiGetData(API_CUSTOMERS_GET_ADMINS, headers)
        dispatch({
            type: ADMINS_CUSTOMERS,
            payload: data
        })
    }

    const addNewCustomer = async ( bodyData ) => {
        const data = apiPostData(API_CUSTOMERS, bodyData, headers)
        if (data.msg) {
            dispatch({
                type: CUSTOMER_ERRORS,
                payload: data.msg
            })
        }else if (data.data) {
            dispatch({
                type: NEW_CUSTOMER,
                payload: data
            })

            swal( ALERT_NEW_CUSTOMER ).then( () => history.push(BASE_LINK) )

        }

    }

    const deleteCustomer = async (url, id) => {

        const resp = await swal(ALERT_DELETE)

        if (!!resp) {
            const data = await apiDeleteData(url, headers)

            if (data.status === 204) {
                const rest = state.customers.filter( item => item.id !== id)
                dispatch({
                    type: DELETE_CUSTOMER,
                    payload: rest
                })
            }
        }

    }

    const customerDeleteErrors = () => {
        dispatch({
            type: CUSTOMER_DELETE_ERRORS,
        })
    }

    return (
        <CustomerContext.Provider
            value={{
                customers: state.customers,
                errors: state.errors,
                customerDeleteErrors,
                deleteCustomer,
                getCustomers,
                getCustomersAdmins,
                addNewCustomer
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    )
}

export default CustomerState
