import React, {useContext, useEffect, useState} from 'react';

// Material
import {Button, Grid, Typography} from "@material-ui/core";

// Context
import ReviewersContext from "context/reviewers/reviewersContext";
import OrdersContext from "context/orders/ordersContext";
import BaseContext from "context/base/baseContext";

// Custom Components
import RejectNotes from "components/modals/RejectNotes";
import ReviewersFromOrder from "./ReviewersFromOrder";

// Constants
import { REVIEW } from "types/orderStatus";
import {API_URL} from '../../types/apiConstants';
import ShowThumbs from "../shared/ShowThumbs";


const Reviewers = () => {

    // Recuperamos userData para saber si el usuario actual es un reviewer
    const baseContext = useContext(BaseContext)
    const {userData} = baseContext

    // Recuperamos los reviewers
    const reviewersContext = useContext(ReviewersContext)
    const {reviewers, acceptReject, getReviewers, getNextReviewer, next, fileReview} = reviewersContext

    // Recuperamos el context de orders
    const ordersContext = useContext(OrdersContext)
    const {orderSelected} = ordersContext

    // Recupera la orden actual
    useEffect(() => {
        (!!orderSelected.id) && getReviewers(orderSelected.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSelected])

    // Recupera el reviewer activo
    useEffect(() => {
        getNextReviewer()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewers])


    // State para abrir/cerrar el modal
    const [reject, setReject] = useState(false)

    const handleClick = (id, accepted) => {

        // byTeam indica si la aceptación es una para todos o por orden. Pasada la gestión al State
        if (accepted) {
            // Aceptado
            acceptReject(id, accepted, orderSelected.id, orderSelected.by_team)

        } else {
            // Rechazado
            // Abrir modal para enviar notas
            setReject(true)
        }

    }

    return (
        <>

            <Typography variant="h3">Reviewers</Typography>
            <div style={{marginTop: 10}}>
                {
                    (orderSelected.status_name === REVIEW) &&
                        <>
                            <Typography variant="body2">File to review: </Typography>
                            <Grid container>
                                <ShowThumbs image={fileReview} />
                            </Grid>

                        </>

                }


                {
                    (!!orderSelected.id && reviewers.length > 0)
                        ? reviewers.map(item => (
                            <div key={item.id}>
                                <Typography variant="body2">{item.reviewer_name} </Typography>

                                {
                                    // Mostrar estado de review y botones solo cuando se está haciendo la revision
                                    (orderSelected.status_name === REVIEW) &&
                                    // Mostrar texto y botones según si se ha aceptado o no
                                    (item.pending)
                                        ?
                                        <>
                                            <Typography variant="body2">
                                                {/*{(item.pending) && ' Pending '}*/}
                                                Pending&nbsp;
                                            </Typography>

                                            {
                                                // Mostrar botones solo si el usuario actual es un reviewer
                                                (userData.email === item.reviewer_name)
                                                &&
                                                (userData.email === next.reviewer_name) &&
                                                <>
                                                    <Button
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        style={{marginLeft: 8}}
                                                        onClick={() => handleClick(next.id, true)}
                                                    >
                                                        Accept
                                                    </Button>

                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        style={{marginLeft: 8}}
                                                        onClick={() => handleClick(next.id, false)}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>

                                            }


                                        </>
                                        :
                                        // Mostrar el texto cuando el tipo de review no está pendiente.
                                        <Typography variant="body2">{(item.accepted) ? 'Accepted' : 'Rejected'}</Typography>

                                }


                            </div>
                        ))

                        :
                        <ReviewersFromOrder reviewers={orderSelected.reviewers}/>

                }
            </div>
            {
                (reject) &&
                <RejectNotes
                    setReject={setReject}
                    userId={next.id}
                    orderSelected={orderSelected.id}
                    reviewFile={`${API_URL}${fileReview.review_file}`}
                />
            }
        </>

    )
}

export default Reviewers