import React, {useContext, useState} from 'react';

// Material
import {
    Button,
    Grid,
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip,
} from '@material-ui/core';

// Material Icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


// Context
import ReviewersContext from "context/reviewers/reviewersContext";
import CustomCanvas from 'pages/whiteboard';

const RejectNotes = ({ userId, orderSelected, setReject, reviewFile }) => {

    // Controla si el modal esta abierto o cerrado
    const [ open, setOpen ] = useState(true)

    // Controla el dialog de modificar imagen
    const [ modifyImg, setModifyImg ] = useState(false)

    // Recuperamos los reviewers
    const reviewersContext = useContext(ReviewersContext)
    const { acceptReject } = reviewersContext

    // Guarda las notas a enviar
    const [ notes, setNotes ] = useState('')

    // Guarda la imagen modificada en base64.
    const [ imgField, setImgField ] = useState('')

    const base64ToBlob = async img64 => {
        const response = await fetch(img64)
        return await response.blob()
    }

    const handleChange = ( { target } ) => {
        setNotes(target.value)
    }

    const handleClose = () => {
        setReject(false)
        setOpen(false)
    }

    const handleModify = () => {
        setModifyImg(true)
    }

    const sendData = async () => {
        const formData = new FormData()

        // Comprobar si hay imagen modificada
        if (!!imgField) {

            // Convertimos base64 en blon(imagen)
            const blobImg = await base64ToBlob(imgField)

            // Adregarla a un bodydata
            formData.append('img_change', blobImg)

            // Agregar el fichero modificado a ReviewersFileReview
        }

        formData.append('accepted', 'false')
        formData.append('pending', 'false')
        formData.append('notes', notes)

        acceptReject(userId, false, orderSelected, false, formData, true)

        // cerrar
        setReject(false)
        setOpen(false)
    }

    const handleSubmitClose = () => {
        // Agregar Reject al usuario (userId, accepted, orderSelected, byTeam, notes)

        sendData().then()
//         const formData = new FormData()
//
//         // Comprobar si hay imagen modificada
//         if (!!imgField) {
// console.log(imgField)
//             base64ToBlob(imgField).then(im => (
//                 setBlobImg([im])
//             ))
//
//
//           console.log(blobImg)
//             // Adregarla a un bodydata
//             for (const file of blobImg) {
//                 formData.append('img_change', file)
//             }
//             // formData.append('img_change', blobImg)
//
//             // Agregar el fichero modificado a ReviewersFileReview
//         }
//         console.log(blobImg)
//         formData.append('accepted', 'false')
//         formData.append('pending', 'false')
//         formData.append('notes', notes)
//
//        // acceptReject(userId, false, orderSelected, false, formData, true)
//
//         // cerrar
//         setReject(false)
//         setOpen(false)
    }


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit review file</DialogTitle>
            <DialogContent>

                    <ul>
                        <li>
                            <Typography variant="body1">
                                If you want to edit the file
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    style={{marginLeft: 5, borderColor: 'blue', color: 'blue'}}
                                    onClick={handleModify}
                                >
                                    click here
                                </Button>
                            </Typography>
                        </li>
                        <li style={{marginTop: 8}}>
                            <Typography variant="body1">
                                Write some notes for the reason to reject. <br />Tip: "Hit enter for new line"
                            </Typography>
                        </li>
                    </ul>
                    {
                        (!!imgField) &&
                            <Grid container justify="center">
                                <Typography
                                    variant="body2"
                                    style={{ borderBottom: '1px solid green', color: 'green', textAlign: 'center' }}>
                                    <Tooltip title="View file in new window" aria-label="view">
                                    <a href={imgField} target="_blank"  rel="noreferrer" style={{color: 'green'}}>
                                        => File edited added
                                    </a>
                                    </Tooltip>
                                    <Tooltip title="Remove file and discard changes" aria-label="delete">
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={() => setImgField('')}
                                        >
                                            <DeleteForeverIcon style={{color: 'red'}} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>


                    }

                <TextField
                    autoFocus
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="text"
                    multiline
                    rowsMax={6}
                    fullWidth
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmitClose} color="primary">
                    Send
                </Button>
            </DialogActions>

            {
                (modifyImg) &&
                    <CustomCanvas
                        image={reviewFile}
                        setUri={setImgField}
                        modifyImg={modifyImg}
                        setModifyImg={setModifyImg}
                    />
            }
        </Dialog>
    )
}

export default RejectNotes