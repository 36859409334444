import React from 'react';

// Icons
import ListAltIcon from '@material-ui/icons/ListAlt';
import TuneIcon from '@material-ui/icons/Tune';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ArchiveIcon from '@material-ui/icons/Archive';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from '@material-ui/icons/People';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';


// Constants
import {
    USERS_CREATE_LINK,
    USERS_LIST_LINK,
    NEW_ORDERS_LINK,
    ORDERS_IN_PROCESS_LINK,
    READY_TO_REVIEW_LINK,
    ORDERS_APPROVED_LINK,
    ORDERS_SENT_LINK,
    ORDERS_ARCHIVE_LINK,
    OPERATORS_LIST_LINK,
    OPERATOR_CREATE_LINK
} from "./linkConstants";

const iconExtra = {
    fontSize: '120%'
}

// const classes = useStyles();

export const ORDERS_MENU = [
    {
        itemName: 'New Orders',
        itemSlug: 'new_order',
        itemLink: NEW_ORDERS_LINK,
        itemIcon: <ListAltIcon style={iconExtra} />
    },
    {
        itemName: 'Orders in Process',
        itemSlug: 'in_process',
        itemLink: ORDERS_IN_PROCESS_LINK,
        itemIcon: <TuneIcon style={iconExtra} />
    },
    {
        itemName: 'Ready to Review',
        itemSlug: 'ready_review',
        itemLink: READY_TO_REVIEW_LINK,
        itemIcon: <PeopleOutlineIcon style={iconExtra} />
    },
    {
        itemName: 'Orders Approved',
        itemSlug: 'approved',
        itemLink: ORDERS_APPROVED_LINK,
        itemIcon: <PlaylistAddCheckIcon style={iconExtra} />
    },
    {
        itemName: 'Orders Sent',
        itemSlug: 'sent',
        itemLink: ORDERS_SENT_LINK,
        itemIcon: <FlightTakeoffIcon style={iconExtra} />
    },
    {
        itemName: 'Orders Archive',
        itemSlug: 'archive',
        itemLink: ORDERS_ARCHIVE_LINK,
        itemIcon: <ArchiveIcon style={iconExtra} />
    },
]

export const DRAWER_ADMIN_MENU = [

    {
        itemName: 'New Customer',
        itemSlug: 'new_user',
        itemLink: USERS_CREATE_LINK,
        itemIcon: <GroupAddIcon />
    },
    {
        itemName: 'List Customers',
        itemSlug: 'list_users',
        itemLink: USERS_LIST_LINK,
        itemIcon: <PeopleIcon />
    },
    {
        itemName: 'New Operator',
        itemSlug: 'new_operator',
        itemLink: OPERATOR_CREATE_LINK,
        itemIcon: <PersonAddIcon />
    },
    {
        itemName: 'List Operators',
        itemSlug: 'list_operators',
        itemLink: OPERATORS_LIST_LINK,
        itemIcon: <SupervisorAccountIcon />
    },

]

export const DRAWER_CUSTOMER_MENU = [
    {
        itemName: 'New User',
        itemSlug: 'new_user',
        itemLink: USERS_CREATE_LINK,
        itemIcon: <GroupAddIcon />
    },
    {
        itemName: 'List Users',
        itemSlug: 'list_users',
        itemLink: USERS_LIST_LINK,
        itemIcon: <PeopleIcon />
    },

]