import React from "react";

// Material
import {
    Chip,
    Grid,
    Typography,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles( (theme) => ({
    mr10: {
        marginRight: 10,
    },

}))


export default function OrderTitle({ colorStatus, description, statusTitle }) {

    const classes = useStyles()

    return (

            <Grid
                container
                justify="center"
                alignItems="center"
            >
                <Typography variant="h1" className={classes.mr10}>{description}</Typography>
                <Chip
                    style={colorStatus}
                    variant="outlined"
                    label={statusTitle}
                    size="small"
                />
            </Grid>

    )
}
