import React, { useContext, useEffect } from 'react';

// Router
import { useLocation }from 'react-router-dom';

// Material
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Context
import BaseContext from "context/base/baseContext";
import OrdersContext from "context/orders/ordersContext";

// Custom Componets
import TableOrder from 'components/orders/table/TableOrders';

// Custom Utils
import { getPagePath } from 'utils/getPagePath';

// Constants
import {
    ORDER_STATUS,
    TITLE_ORDER_STATUS
} from 'types/orderStatus';


const OrderStatus = () => {

    // Recupera context para título
    const baseContext = useContext(BaseContext)
    const { setTitle } = baseContext

    // Recuperamos el context de orders
    const ordersContext = useContext(OrdersContext);
    const { orders, pageOrders, getPageOrders } = ordersContext

    // Recuperamos el nombre de la página para usar como estado
    const location = useLocation()
    const pageName = getPagePath(location.pathname)

    // Titulo de la página
    useEffect( () => {
        setTitle(TITLE_ORDER_STATUS[pageName])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Generamos un efecto para recuperar las orders de la página actual(order_estatus)
    useEffect( () => {
        getPageOrders(ORDER_STATUS[pageName])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders])

    return (

        // <div className="container height900vh d-flex align-items-center justify-content-center flex-column" >
        <Grid 
            container
            spacing={3}
            justify="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={10} md={8}>

            {
                (pageOrders && pageOrders.length > 0)
                ? 
                    <TableOrder itemData={pageOrders} /> 
                : 
                <Alert severity="warning">No orders to show</Alert>
            }

            </Grid>
        </Grid>

       
    )
}

export default OrderStatus