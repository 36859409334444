import React from "react";

// Material
import {
    Grid,
    Typography
} from "@material-ui/core";


export default function InfoUploadLayout({ info_upload, classes }) {
    return (
        <>
            {
                (!!info_upload && info_upload.length > 0)
                &&


                    <Grid item xs={12} sm={12} lg={4} className={classes.gridPadding}>


                        <Typography variant="h3" className={classes.mb10}>Files uploadeds:</Typography>
                        {info_upload.map( item => (
                            <Typography variant="h4" key={item.id}>

                                File Name:&nbsp;
                                <Typography variant="body2">
                                    <a href={item.file_upload} target="_blank" rel="noreferrer">
                                        {item.file_name}
                                    </a>
                                </Typography>


                            </Typography>
                        ))}


                    </Grid>


            }
        </>
    )
}
