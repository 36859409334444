import {
    VALIDATION_ERRORS,
    DELETE_ERRORS,
    LIST_ORDERS,
    NEW_ORDER,
    MODIFY_ORDER,
    GET_ORDER_STATUS,
    ORDER_DATA,
    ORDER_SELECTED
} from 'types/types';

const OrdersReducer = (state, action) => {
    switch (action.type) {
        case VALIDATION_ERRORS:
            return {
                ...state,
                errors: action.payload
            }

        case DELETE_ERRORS:
            return {
                ...state,
                errors: {}
            }
        
        case GET_ORDER_STATUS:
            return {
                ...state,
                pageOrders: action.payload
            }

        case ORDER_SELECTED:

            return  {
                ...state,
                orderSelected: {...action.payload},
            }

        case MODIFY_ORDER:
            return  {
                ...state,
                orders: [
                    ...state.orders.filter( item => item.id !== action.payload.id ),
                    action.payload
                ]
            }
            
        case NEW_ORDER:
            return {
                ...state,
                orders: [
                    ...state.orders,
                    action.payload,
                ],
                errors: ''
            }

        case LIST_ORDERS:
            return {
                ...state,
                orders: action.payload
            }

        case ORDER_DATA:
            return {
                ...state,
                orderData: action.payload
            }

        default:
            return state
    }
}

export default OrdersReducer