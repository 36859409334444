import React, { Fragment } from "react";

// Material
import {
    Grid,
    Typography
} from "@material-ui/core";


export default function TypesMeasuresLayout({ order_type, classes }) {
    return (
        <Grid item xs={12} lg={6} className={classes.gridPadding}>
            <Typography variant="h3" className={classes.mb10}>Order Types and measures: </Typography>
            {
                (order_type && order_type.length > 0)
                &&
                order_type.map( data => (
                    <Fragment key={data.id} >
                        <Typography variant="h4">
                            {data.order_type_title}:
                        </Typography>
                        <Typography variant="h4" style={{paddingLeft: 20}}>
                            Width:&nbsp;
                            <Typography variant="body2">
                                {data.width} {data.order_type_measure} -&nbsp;
                            </Typography>
                            Height:&nbsp;
                            <Typography variant="body2">
                                {data.height} {data.order_type_measure}
                            </Typography>
                        </Typography>
                    </Fragment>
                ))
            }
        </Grid>
    )
}
