export const ALERT_NEW_ORDER = {
    title: 'New Order',
    text: 'Order successfully created',
    icon: 'success'
}

export const ALERT_NEW_CUSTOMER = {
    title: 'New Customer',
    text: 'Customer successfully created',
    icon: 'success'
}

export const ALERT_NEW_OPERATOR = {
    title: 'New Operator',
    text: 'Operator successfully created',
    icon: 'success'
}

export const ALERT_CHANGE_ASSIGN = {
    title: 'Change Status',
    text: 'Status successfully changed to... In Process',
    icon: 'success'
}

export const ALERT_UPLOADED = {
    title: 'Upload file',
    text: 'File was successfully uploaded.\n You will receive an E-mail when link has been created.',
    icon: 'success'
}

export const ALERT_UPLOAD_ERROR = {
    title: 'Upload file Error',
    text: 'Something was wrong uploading file',
    icon: 'error'
}

export const ALERT_DELETE = {
    title: 'Delete data',
    text: 'Are you sure you want to delete?',
    icon: 'warning',
    buttons: true,
}