import { useState } from 'react';

// Material
import { Grid, makeStyles, Tooltip } from '@material-ui/core';

// Material icons
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    aire: {
        marginTop: 20,
        paddingLeft:10
    },
    centraAsterisco: {
        paddingTop: 5
    }
}))

export default function OrderLink( {orderLink} ) {

    const classes = useStyles()

    const [ showPass, setShowPass ] = useState( false )
    const { file_link, file_pass } = orderLink

    const showOrNot = (showPass) ? 'Hide' : 'Show'

    return (
        <Grid container className={classes.aire} >
            <Grid item xs={12} lg={4} className={classes.center} >
                File link:&nbsp;
                <a href={ file_link } target="_blank" rel="noreferrer"> Go to file</a>
            </Grid>
            <Grid item xs={12} lg={8} className={classes.center} >
                <Grid container>
                    <Grid item xs={11} className={classes.center} >
                        Password:&nbsp;
                        <span className={(!showPass) && classes.centraAsterisco} >
                            {
                                (showPass)
                                    ? <>{file_pass}</>
                                    : '***********'
                            }
                        </span>    
                    </Grid>
                    <Grid item xs={1} className={classes.center} >
                        <Tooltip title={`${showOrNot} password.`}>
                            <IconButton aria-label="View" onClick={ () => setShowPass(!showPass)}>
                                {
                                    (showPass)
                                        ? <VisibilityOffIcon />
                                        : <VisibilityIcon />
                                }
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    
    )
}