import React, {useContext, useReducer} from 'react';

// Context
import OrderCommentsContext from './orderCommentsContext';
import BaseContext from "../base/baseContext";

// Reducer
import OrderCommentsReducer from "./orderCommentsReducer";

// Custom services
import apiGetData from "services/apiGetData";
import apiPostData from "services/apiPostData";

// Constants Types
import { API_ORDER_COMMENTS } from "types/apiConstants";
import {
    GET_COMMENTS,
    POST_COMMENT
} from "types/types";


export default function OrderCommentsState({children}) {

    const initialData = {
        orderComments: []
    }

    const baseContext = useContext(BaseContext)
    const { headers } = baseContext

    const [ state, dispatch ] = useReducer(OrderCommentsReducer, initialData)


    const getComments = async orderId => {
        const url = `${API_ORDER_COMMENTS}?order_id=${orderId}`
        const data = await apiGetData(url, headers)
        dispatch({
            type: GET_COMMENTS,
            payload: data
        })

    }

    const newComment = async bodyData => {
        const data = await apiPostData( API_ORDER_COMMENTS, bodyData, headers)
        console.log(data)
        dispatch({
            type: POST_COMMENT,
            payload: data
        })
    }

    return (
        <OrderCommentsContext.Provider
            value={{
                orderComments: state.orderComments,
                getComments,
                newComment
            }}
        >
            {children}
        </OrderCommentsContext.Provider>
    )
}
