import React from 'react';

// Router
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

// Material
import {MuiThemeProvider} from '@material-ui/core/styles';

// Custom Theme
import {tracerTheme} from 'theme/tracerTheme'

// States
import BaseState from 'context/base/baseState';
import AuthState from 'context/auth/authState';
import FileReviewState from "context/fileReview/fileReviewState";
import ReviewersState from "context/reviewers/reviewersState";
import OperatorsState from 'context/operators/operatorsState';
import OrdersState from 'context/orders/ordersState';
import OrderCommentsState from 'context/orderComments/orderCommentsState';
import CustomerState from 'context/customers/customerState';


// Custom Pages
import HomeScreen from 'pages/Home/HomeScreen';
import LoginScreen from 'pages/Login/LoginScreen';
import LandingReviewerScreen from "./pages/NewReviewer/LandingReviewerScreen";


// Constants
import {
    LANDING_CHANGE_LINK,
    LANDING_CHECK_LINK, LANDING_CHECK_LINK_PARAMS,
    LOGIN_LINK,
} from "types/linkConstants";
import LandingReviewerChangeScreen from "pages/NewReviewer/LandingReviewerChangeScreen";


function App() {

    return (
        <MuiThemeProvider theme={tracerTheme}>

            <BaseState>
                <AuthState>
                    <Router>
                        <OrdersState>
                            <OrderCommentsState>
                                <OperatorsState>
                                    <CustomerState>
                                        <FileReviewState>
                                            <ReviewersState>

                                                <Switch>
                                                    {/*Página de login*/}
                                                    <Route path={LOGIN_LINK}>
                                                        <LoginScreen/>
                                                    </Route>

                                                    {/*Landing para new reviewers*/}
                                                    <Route exact path={LANDING_CHECK_LINK}>
                                                        <LandingReviewerScreen/>
                                                    </Route>
                                                    <Route exact path={LANDING_CHECK_LINK_PARAMS}>
                                                        <LandingReviewerScreen/>
                                                    </Route>
                                                    <Route exact path={LANDING_CHANGE_LINK}>
                                                        <LandingReviewerChangeScreen/>
                                                    </Route>

                                                    {/*Routes de la app*/}
                                                    <Route path='/'>
                                                        <HomeScreen/>
                                                    </Route>

                                                </Switch>

                                            </ReviewersState>
                                        </FileReviewState>
                                    </CustomerState>
                                </OperatorsState>
                            </OrderCommentsState>
                        </OrdersState>
                    </Router>
                </AuthState>
            </BaseState>
        </MuiThemeProvider>

    );
}

export default App;
