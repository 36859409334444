import { useContext, useState, useEffect } from "react";

// Router
import { useHistory } from 'react-router-dom';

// Material
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
} from "@material-ui/core";

// SweetAlert
import swal from 'sweetalert';

// Custom Services
import apiPostData from "services/apiPostData";

// Context
import BaseContext from "context/base/baseContext";

// Constants
import { API_UPLOAD_TEMP_FILE } from "types/apiConstants";
import {ALERT_UPLOAD_ERROR, ALERT_UPLOADED} from "types/alertTypes";
import {BASE_LINK} from "types/linkConstants";

// Custom Css
import 'assets/css/sweet-alert-text-center.css';


export default function WaitUpload({ setWaiting, fileUpload, orderId }) {

    const history = useHistory()

    const [ uploadingOpen, setUploadingOpen ] = useState(true);

    const handleClose = () => {
        setUploadingOpen(false)
        setWaiting(false)
    }

    const baseContext = useContext(BaseContext)
    const { headersFiles } = baseContext

    useEffect( () => {
            doUpload().then()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sendFile = async (formData) => {
        const data = await apiPostData(API_UPLOAD_TEMP_FILE, formData, headersFiles, true)
        return !!data.id
    }

    const doUpload = async () => {
        const formData = new FormData()
        formData.append('order', orderId)

        if (fileUpload.length > 0) {

            for (const file of fileUpload) {
                formData.append('temp_file', file)
            }
        }
        const uploaded = await sendFile(formData)

        if (uploaded) {
            swal( ALERT_UPLOADED ).then( () => history.push(BASE_LINK) )
        }else {
            swal( ALERT_UPLOAD_ERROR ).then( handleClose )
        }
    }

    return (
        <Dialog
            open={uploadingOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick="true"
        >
            <DialogTitle id="form-dialog-title">Uploading file</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Uploading file. Don't close this window while file is uploading
                </DialogContentText>
                <Grid container justify={'center'}>
                    <CircularProgress color="secondary" />
                </Grid>

            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>

    )
}