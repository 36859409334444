import React, {useContext, useEffect} from 'react';

// Router
import {useParams} from 'react-router-dom';

// Material
import { Grid, makeStyles } from "@material-ui/core";

// Context
import BaseContext from 'context/base/baseContext';
import OrdersContext from 'context/orders/ordersContext';

// Custom Pages
import Comments from "components/comments/Comments";

// Custom components
import OrderTitle from "components/orders/layouts/OrderTitle";
import OrderStatusFileReview from "components/orders/layouts/OrderStatusFileReview";
import OrderDataLayout from "components/orders/layouts/OrderDataLayout";

// Constants
import {COLOR_ORDER_STATUS, TITLE_ORDER_STATUS} from 'types/orderStatus';


const useStyles = makeStyles((theme) => ({
    marginAll: {
        '& > *': {
            marginTop: 15
        }
    },
    hr: {
        width: '100%',
        border: '1px solid lightgrey'
    }

}))


const OrderDetail = () => {

    // Styles classes
    const classes = useStyles()

    // Recuperamos los parámetros recibidos
    const params = useParams()

    // Recupera los datos del usuario
    const baseContext = useContext(BaseContext)
    const {setTitle} = baseContext

    // Recuperamos el context de orders
    const ordersContext = useContext(OrdersContext)
    const { orders, orderSelected, selectOrder } = ordersContext

    // Efecto para cambiar el title de la appbar
    useEffect(() => {
        setTitle('Order Detail')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        selectOrder(params.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orders])

    // Destructuring para mejor manejo de los datos de pageOrder
    const {
        description, id, status_id, operator, status_name,
    } = orderSelected

    // Colores del Chip del Status
    const statusTitle = TITLE_ORDER_STATUS[status_name]
    const colorStatus = {...COLOR_ORDER_STATUS[status_name], marginTop: 8}

    return (
        <Grid
            container
            justify="center"
            direction="column"
            className={classes.marginAll}
        >
            {/*Título y estado*/}
            <Grid container justify="center">
                <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                    <OrderTitle
                        colorStatus={colorStatus}
                        description={description}
                        statusTitle={statusTitle}
                    />
                </Grid>
            </Grid>

            <hr className={classes.hr}/>

            {/*Cambio de estado y fileReview*/}
            <Grid container justify="center">
                <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                    <OrderStatusFileReview
                        id={id}
                        status_id={status_id}
                        operator={operator}
                    />
                </Grid>
            </Grid>

            {/*Datos de la orden*/}
            <Grid container justify="center">

                <OrderDataLayout
                    orderSelected={orderSelected}
                />
            </Grid>

            <hr className={classes.hr}/>

            {/*Comentarios*/}
            <Grid container justify="center">
                <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                    <Comments/>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default OrderDetail
