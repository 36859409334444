import { useState } from "react";

// Material
import { Button, Grid, Typography } from "@material-ui/core";

// Custom Components
import UploaTempFile from 'components/modals/UploaTempFile';
import SendFileLink from 'components/modals/SendFileLink'

export default function OrderSentSelect({ orderId }) {
// Enviar OrderID
    const [ openFileUpload, setOpenFileUpload ] = useState(false)
    const [ openFileLink, setOpenFileLink ] = useState(false)

    return (
        <>
            <Grid container >
                <Grid item>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setOpenFileUpload(true)}
                    >Upload File</Button>
                    <Typography variant="body">
                        &nbsp; or &nbsp;
                    </Typography>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenFileLink(true)}
                    >Send link</Button>
                </Grid>
            </Grid>

            {
                (openFileUpload) &&
                    <UploaTempFile
                        orderId={orderId}
                        setOpenFileUpload={setOpenFileUpload}
                    />

            }

            {
                (openFileLink) &&
                    <SendFileLink
                        orderId={orderId}
                        setOpenFileLink={setOpenFileLink}
                    />
            }
        </>
    )
}