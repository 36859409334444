// Auth
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_DELETE_ERRORS = 'LOGIN_DELETE_ERRORS';

export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const LOGOUT = 'LOGOUT';

// Base
export const SET_TITLE = 'SET_TITLE';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_HEADER_TOKEN = 'SET_HEADER_TOKEN';
export const REMOVE_HEADER_TOKEN = 'REMOVE_HEADER_TOKEN';

// Orders
export const ORDER_DATA = 'ORDER_DATA';
export const NEW_ORDER = 'NEW_ORDER';
export const LIST_ORDERS = 'LIST_ORDERS';
export const VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const DELETE_ERRORS = 'DELETE_ERRORS';
export const MODIFY_ORDER = 'MODIFY_ORDER';
export const GET_ORDER_STATUS = 'GET_ORDER_STATUS';
export const ORDER_SELECTED = 'ORDER_SELECTED';

// Order Comments
export const GET_COMMENTS = 'GET_COMMENTS';
export const POST_COMMENT = 'POST_COMMENT';

// Customers
export const LIST_CUSTOMERS = 'LIST_CUSTOMERS';
export const ADMINS_CUSTOMERS = 'ADMINS_CUSTOMERS';
export const NEW_CUSTOMER = 'NEW_CUSTOMER';
export const CUSTOMER_DELETE_ERRORS = 'CUSTOMER_DELETE_ERRORS';
export const CUSTOMER_ERRORS = 'CUSTOMER_ERRORS';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

// Operators
export const LIST_OPERATORS = 'LIST_OPERATORS';
export const NEW_OPERATOR = 'NEW_OPERATORS';
export const OPERATORS_DELETE_ERRORS = 'OPERATORS_DELETE_ERRORS';
export const OPERATORS_ERRORS = 'OPERATORS_ERRORS';
export const DELETE_OPERATOR = 'DELETE_OPERATOR';

// Reviewers
export const GET_REVIEWERS = 'GET_REVIEWERS';
export const ACCEPT_REJECT = 'ACCEPT_REJECT';
export const NEXT_REVIEWER = 'NEXT_REVIEWER';
export const GET_REJECTED = 'GET_REJECTED';

// File Reviews
export const ADD_REVIEW_FILE = 'ADD_REVIEW_FILE';
export const DISABLE_REVIEW_FILE = 'DISABLE_REVIEW_FILE';

// Spinner
export const SET_SPINNER = 'SET_SPINNER';
