import React, {useState} from 'react';

// Material
import {Button, TextField} from "@material-ui/core";


const LandingReviewerChangeScreen = () => {

    const [ bodyForm, setBodyForm ] = useState({
        password: '',
        repassword: '',
        name: ''
    })

    // Recuperar token y comprobarlo

    // Si el token no es correcto, error y login

    // Mostrar formulario para recoger datos y password

    // Comprobar pass1 y pass2

    // Enviar formulario con Token

    const handleChange = ( { target } ) => {
        setBodyForm({
            ...bodyForm,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (bodyForm.password.trim() !== bodyForm.repassword.trim()) {
            console.log('Los passwords no son iguales')
        }else {
            console.log('hacer submit')
        }
    }

    return (

        <>

            <h3>User details</h3>
            <form
                method="POST"
                encType="multipart/form-data"
                className="w-100 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <TextField
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    value={bodyForm.name}
                />
                <TextField
                    name="password"
                    label="Password"
                    onChange={handleChange}
                    value={bodyForm.password}
                />
                <TextField
                    name="repassword"
                    label="Retype Password"
                    onChange={handleChange}
                    value={bodyForm.repassword}
                />
                {/*{ errors.error && errors.type }*/}

                <Button type="submit" variant="outlined" color="primary" >Send</Button>
            </form>
        </>

    )
}

export default LandingReviewerChangeScreen