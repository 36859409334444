import React from 'react';

// Material
import { Typography } from "@material-ui/core";




const ReviewersFromOrder = ({ reviewers }) => {

    const allReviewers = (!!reviewers) ? reviewers.split('|') : []

    return (
        <>
            {
                allReviewers.map( item => (
                    <div key={item}>
                        <Typography variant="body2">{item}</Typography>

                    </div>
                ))
            }

        </>

    )
}

export default ReviewersFromOrder