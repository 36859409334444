import React, { useContext, useEffect } from 'react';

// Material
import {Grid} from '@material-ui/core';

// Context
import CustomerContext from 'context/customers/customerContext';

// Custom Components
import TableUsers from 'components/users/TableUsers';
import {API_CUSTOMERS} from "../../types/apiConstants";

const CustomersList = () => {

    const customerContext = useContext(CustomerContext);
    const { customers, getCustomers, deleteCustomer } = customerContext
    useEffect( () => {
        getCustomers()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
        >
            <Grid item xs={6} style={{textAlign: 'center'}}>
                {
                    (customers && customers.length > 0)
                        ?
                        <TableUsers
                            itemData={customers}
                            text="Customer"
                            link={{
                                url: API_CUSTOMERS,
                                action: deleteCustomer
                            }}
                        />
                        :
                        <span className="alert alert-warning w-75 text-center">No customers to show</span>
                }
            </Grid>
        </Grid>

    )
}

export default CustomersList
