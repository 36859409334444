import React, { useContext } from "react";

// Material
import {
    Grid,

} from "@material-ui/core";

// Context
import BaseContext from "context/base/baseContext";

// Custom Components
import OrderDetailChangeStatus from "components/orders/buttons/OrderDetailChangeStatus";
import ReviewersFileReview from "components/reviewers/ReviewersFileReview";

export default function OrderStatusFileReview({ id, status_id, operator }) {

    const baseContext = useContext(BaseContext)
    const { userData } = baseContext

    const superUserStaff = (userData.is_superuser || userData.is_staff)

    return (

            <Grid
                container
                direction="column"
                // justify="center"
                style={{ marginTop: 10 }}
            >
                {
                    (superUserStaff) &&
                    // <Grid item xs={12} lg={6} className={classes.gridPadding}>
                        <OrderDetailChangeStatus
                            id={id}
                            status_id={status_id}
                            operator={operator}
                        />
                    // </Grid>
                }

                <ReviewersFileReview />


            </Grid>

    )
}
