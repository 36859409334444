import React, { useState, useContext } from 'react';

// Material
import { Button, FormLabel, TextField, makeStyles } from "@material-ui/core";
import clsx from 'clsx';

// DropZone
import { useDropzone } from 'react-dropzone';

// Context
import FileReviewContext from 'context/fileReview/fileReviewContext'
import ReviewersContext from "../../context/reviewers/reviewersContext";


const useStyles = makeStyles((theme) => ({
    full: {
        width: '100%',
    },
    fields: {
        marginBottom: 5,
    }
}))

const SendReviewFile = ({order_id, order_status}) => {

    const styles = useStyles()

    const fileReviewContext = useContext(FileReviewContext)
    const { orderSendReviewFile } = fileReviewContext

    // Context para saber si hay un fichero review rechazado.
    const reviewerContext = useContext(ReviewersContext)
    const {fileReview} = reviewerContext
    const {id} = fileReview

    // Datos a enviar
    const [ bodyData, setBodyData ] = useState({
        name: '',
        description: '',
        file: '',
        actived: true
    })

    // State para guardar el fichero a subit
    const [ file, setFile ] = useState([])

    const handleChange = ({ target }) => {

        // Si se recibe un fichero se asigna al state de files
        if (target.type === 'file'){
            setFile(target.files[0])
        }

        setBodyData({
            ...bodyData,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        // Validations
        const formData = new FormData()

        // Si el nombre no está definido se usa el nombre del archivo
        if ( !bodyData.name && !!bodyData.file ) bodyData.name = file.name

        for (let key in bodyData){

            // Convierte los datos del formulario en un FormData, necesario si se quiere subir archivos
            formData.append(key, bodyData[key])
        }

        // Agregamos fichero a enviar
        formData.append('review_file', file[0])

        // Agrega order_id
        formData.append('order_id', order_id)
        formData.append('Hola', 'yeeeeeee')

        orderSendReviewFile(formData, order_status, order_id, id)
    }

    // Manejo de ficheros por DropZone
    const onDrop = newFile => setFile(newFile)

    // Flie Drang & Drop
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <form
            method="POST"
            encType="multipart/form-data"
            onSubmit={handleSubmit}
            className={styles.full}
        >

            <TextField
                name="name"
                label="Name"
                className={clsx(styles.full, styles.fields)}
                value={bodyData.name}
                onChange={handleChange}
            />


            <TextField
                name="description"
                label="Description"
                className={clsx(styles.full, styles.fields)}
                value={bodyData.description}
                onChange={handleChange}
                multiline
                rowsMax={6}
            />

            <section className="container" style={{marginTop: '15px'}}>
                <FormLabel component="legend">Add file to review</FormLabel>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input
                        {...getInputProps()}
                    />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </section>

            <Button
                type="submit"
                variant="outlined"
                color="primary" >
                Send and Change Status
            </Button>

        </form>
    )
}

export default SendReviewFile
