import React, { useContext, useEffect } from 'react';

// Material
import {Grid} from '@material-ui/core';

// Context
import OperatorsContext from 'context/operators/operatorsContext';

// Custom Components
import TableUsers from 'components/users/TableUsers';
import {API_OPERATORS} from "../../types/apiConstants";


const OperatorsList = () => {

    const operatorsContext = useContext(OperatorsContext);
    const { operators, getOperators, deleteOperator } = operatorsContext

    useEffect( () => {
        getOperators()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (

        <Grid
            container
            justify="center"
            alignItems="center"
        >
            <Grid item xs={6} style={{textAlign: 'center'}} >
                { (operators && operators.length > 0)
                    ?
                        <TableUsers
                            itemData={operators}
                            text="Operator"
                            link={{
                                url: API_OPERATORS,
                                action: deleteOperator
                            }}
                        />
                    :
                        <span className="alert alert-warning w-75 text-center">No operators to show</span>
                }
            </Grid>
        </Grid>

    )
}

export default OperatorsList
