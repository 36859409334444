import React, { useContext, useReducer} from 'react';

// Router
import { useHistory } from 'react-router-dom';

// SweetAlert
import swal from "sweetalert";

// Context
import BaseContext from 'context/base/baseContext';

import OperatorsContext from './operatorsContext';
// Reducer
import OperatorsReducer from './operatorsReducer';

// Constants
import {
    DELETE_OPERATOR,
    LIST_OPERATORS,
    NEW_OPERATOR,
    OPERATORS_DELETE_ERRORS,
    OPERATORS_ERRORS
} from 'types/types';
import { API_OPERATORS } from 'types/apiConstants';
import {ALERT_DELETE, ALERT_NEW_OPERATOR} from "../../types/alertTypes";
import { BASE_LINK } from "../../types/linkConstants";
import apiGetData from "../../services/apiGetData";
import apiPostData from "../../services/apiPostData";
import apiDeleteData from "../../services/apiDeleteData";

const OperatorsState = props => {

    // Estado inicial
    const initialState = {
        operators: [],
        errors: {}
    }

    // History
    const history = useHistory()

    // Context
    const baseContext = useContext(BaseContext);
    const { headers } = baseContext

    // Reducer
    const [ state, dispatch ] = useReducer(OperatorsReducer, initialState)

    const getOperators = async () => {
        const data = await apiGetData(API_OPERATORS, headers)
        dispatch({
            type: LIST_OPERATORS,
            payload: data})
    }

    const addNewOperator = async (bodyData) => {

        const data = await apiPostData(API_OPERATORS, bodyData, headers)
        if (data.msg) {
            dispatch({
                type: OPERATORS_ERRORS,
                payload: data.msg
            })
        }else if (data.data) {
            dispatch({
                type: NEW_OPERATOR,
                payload: data
            })

            swal( ALERT_NEW_OPERATOR ).then( () => history.push(BASE_LINK) )
        }

    }

    const deleteOperator = async (url, id) => {

        const resp = await swal(ALERT_DELETE)

        if (!!resp) {
            const data = await apiDeleteData(url, headers)

            if (data.status === 204) {
                const rest = state.operators.filter(item => item.id !== id)
                dispatch({
                    type: DELETE_OPERATOR,
                    payload: rest
                })
            }
        }
    }

    const operatorsDeleteErrors = () => {
        dispatch({
            type: OPERATORS_DELETE_ERRORS,
        })
    }

    return (
        <OperatorsContext.Provider
            value={{
                operators: state.operators,
                errors: state.errors,
                deleteOperator,
                getOperators,
                addNewOperator,
                operatorsDeleteErrors

            }}
        >
            {props.children}
        </OperatorsContext.Provider>
    )
}

export default OperatorsState
