import React, { useContext, useEffect } from 'react';

// Context
import BaseContext from "context/base/baseContext";
import OrdersContext from "context/orders/ordersContext";
import OrderCommentsContext from "context/orderComments/orderCommentsContext";

// Custom Components
import NewComment from "./NewComment";
import LastComments from "./LastComments";


export default function Comments () {

    // Recupera los datos del usuario
    const baseContext = useContext(BaseContext)
    const { userData } = baseContext

    // Recuperamos el context de orders
    const ordersContext = useContext(OrdersContext)
    const { orderSelected } = ordersContext

    // Context para los comentarios
    const orderCommentsContext = useContext(OrderCommentsContext)
    const { orderComments, getComments } = orderCommentsContext

    useEffect(() => {
        getComments(orderSelected.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSelected])

    return (
        <>

            <h3>Comments:</h3>

            <NewComment orderId={orderSelected.id} userId={userData.id} />

            <LastComments orderComments={orderComments} userId={userData.id} />

        </>

    )
}
