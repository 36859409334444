import {useState} from "react";

// Material
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    DialogContentText
} from "@material-ui/core";

// Custom Components
import WaitingUpload from './WaitingUpload'


export default function UploadTempFile({ setOpenFileUpload, orderId }) {

    const [open, setOpen] = useState(true);
    const [ fileUpload, setFileUpload ] = useState(null)
    const [ waiting, setWaiting ] = useState(false)




    const handleChange = ({ target }) => {
        setFileUpload(target.files)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenFileUpload(false)
    };

    const handleSubmit = e => {
        e.preventDefault()



        setWaiting(true)

    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Upload file to proccess link in SharePoint (Onedrive).
                    </DialogContentText>
                    <input
                        type="file"
                        name="file_upload"
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Send File
                    </Button>
                </DialogActions>
            </Dialog>

            {
                (waiting) &&
                    <WaitingUpload setWaiting={setWaiting} fileUpload={fileUpload} orderId={orderId}/>
            }
        </>
    )
}

