import React from 'react';

// Router
import { Link } from 'react-router-dom';

// Material
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Chip } from '@material-ui/core';

//Constants
import {
    ORDERS_LINK,
    DETAIL_ORDER_NAME
} from 'types/linkConstants'
import { COLOR_ORDER_STATUS, TITLE_ORDER_STATUS } from 'types/orderStatus';
import { ORDERS_MENU } from 'types/menuConstants';


const TrTableOrder = ( { item } ) => {

    const status = (!!item) ? ORDERS_MENU[item.order_status -1] : {}
    const statusTitle = (!!status) ? TITLE_ORDER_STATUS[status.itemSlug] : ''
    const colorStatus = (!!status) ? COLOR_ORDER_STATUS[status.itemSlug] : ''

    return (
        <TableRow key={item.id}>
            {/*<TableCell component="th" scope="row">*/}
            {/*    {item.id}*/}
            {/*</TableCell>*/}
            <TableCell>
                <Link to={`${ORDERS_LINK}${item.id}${DETAIL_ORDER_NAME}`}>
                    {item.description}
                </Link>
            </TableCell>
            <TableCell>
                <Link to={`${status.itemLink}`}>
                    <Chip
                        style={colorStatus}
                        variant="outlined"
                        label={statusTitle}
                        size="small"
                        clickable />
                </Link>
            </TableCell>
        </TableRow>
    )
}

export default TrTableOrder
