import React, {useState, useEffect, useContext} from 'react';

// Router
import {Link} from 'react-router-dom';

// Material
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Typography
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';

// Material Icons
import IconButton from "@material-ui/core/IconButton";
import {Delete} from "@material-ui/icons";

// DropZone
import {useDropzone} from 'react-dropzone';

// Context
import OrderContext from "context/orders/ordersContext";
import BaseContext from 'context/base/baseContext';

// Custom Components
import InputWidthHeight from 'components/shared/InputWidthHeight';
import CustomDD from '../../components/shared/CustomDD';

// Constants
import {DASHBOARD_LINK} from 'types/linkConstants';
import {EMAIL_CHECK} from 'types/orderStatus';


const useStyles = makeStyles((theme) => ({
    w100: {
        width: '100%'
    },
    textEnd: {
        textAlign: 'end'
    }
}))

const NewOrder = () => {

    // Style classes
    const classes = useStyles()

    // Context con los datos para el formulario
    const baseContext = useContext(BaseContext);
    const {setTitle} = baseContext

    // Context con los datos para el formulario
    const orderContext = useContext(OrderContext);
    const {orderData, getOrderData, errors, setErrors, addNewOrder, deleteErrors} = orderContext

    // Destructuring orderData
    const {order_urgency, order_type, order_status} = orderData

    const statusId = (!!order_status) ? order_status.filter(data => data.default) : null

    // Estado para seleccionar Type y poder mostrar las medidas del seleccionado
    const [selectedTypes, setSelectedTypes] = useState([])
    const [selectedTypesData, setSelectedTypesData] = useState([])

    // Tags para reviewers
    const [reviewerTags, setReviewersTags] = useState({
        items: []
    })

    // Tags para suppliers
    const [suppliersTags, setSuppliersTags] = useState([])

    // State para guardar el fichero a subir
    const [addFiles, setAddFiles] = useState([])

    // Recupera los datos básicos de una Order
    useEffect(() => {
        getOrderData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Asigna el Título en la AppBar
    useEffect(() => {
        setTitle('New Order')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Elimina los errores de los campos.
    useEffect(() => {
        deleteErrors()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Manejador de datos del formulario
    const [orderBody, setOrderBody] = useState({
        description: '',
        specs: '',
        adittional_info: '',
        comments: '',
        order_urgency: '1',
    })

    const handleChange = ({target}) => {

        !!errors[target.name] && delete errors[target.name]

        // Si se recibe un fichero se asigna al state de files
        if (target.type === 'file') {
            setAddFiles(target.files)
        }

        // Chequea si se ha seleccionado un type.
        const isType = order_type.filter(type => {

            return type.type_title === target.name
        })

        if (isType.length > 0) {

            if (target.checked) {

                // Si se ha seleccionado un type agregarlo a selectedtypes para mostrar width y height
                const data = {
                    id: isType[0].id,
                    title: isType[0].type_title,
                    measure_type: isType[0].measure_type,
                    width: '',
                    height: ''
                }

                setSelectedTypes([
                    ...selectedTypes,
                    data
                ])

            } else {
                // Si se ha deseleccionado eliminarlo de selected y se deja de mostrar width y height
                (selectedTypes.length > 0) && setSelectedTypes(
                    selectedTypes.filter(item => {
                        return item.title !== target.name
                    })
                )
            }
            for (let item of isType) {
                setOrderBody({
                    ...orderBody,
                    [item.type_title]: item.id
                })
            }
        } else {
            // Actualiza los datos de los campos con su valor.
            // Si es un checkbox de material se pasa a Entero
            setOrderBody({
                ...orderBody,
                [target.name]: (target.name === 'order_urgency') ? parseInt(target.value) : target.value
            })
        }

    }

    const keyDownReviewers = evt => {

        // Si hay errores borrarlos
        if (!!errors.reviewers) deleteErrors()

        // Comprueba si se ha pulsado intro
        if (evt.keyCode === 13) {

            // Previene el submit al pulsar intro
            evt.preventDefault()

            // Si se pulsa intro y no hay nada escrito, no se hace nada
            if (!evt.target.value) return

            // Comprueba si es un email correcto
            if (!EMAIL_CHECK.test(evt.target.value)) {
                setErrors({
                    reviewers: "Please input a valid email. ex; example@domain.com"
                })
            } else {

                // Agrega el valor al objeto
                setReviewersTags({

                    items: [
                        ...reviewerTags.items,
                        {
                            id: reviewerTags.items.length.toString(),
                            content: evt.target.value
                        }
                    ]
                })

                evt.target.value = ''
            }
        }

    }

    const handleSubmit = e => {
        e.preventDefault()

        if (reviewerTags.items.length === 0) {
            setErrors({
                reviewers: "Reviewers can't be blank"
            })

        }
        // TODO Validaciones Print, Digital, Video.
        if (Object.keys(errors).length === 0) createOrder()
    }

    // CHIPS
    const handleAddChip = (chip, supplier = false) => {
        if (EMAIL_CHECK.test(chip)) {
            if (supplier)
                setSuppliersTags([...suppliersTags, chip])
            // : setReviewersTags([...reviewerTags, chip])

        } else {
            console.log('Is not a mail') // Todo Validation
        }
    }

    const handleDeleteChip = (chip, index, supplier = false) => {
        if (supplier)
            (suppliersTags.length > 0)
                && setSuppliersTags(suppliersTags.filter((tag, idx) => idx !== index))
        // : (reviewerTags.length > 0)
        // && setReviewersTags(reviewerTags.filter((tag, idx) => idx !== index))

    }

    const createOrder = () => {
        const formData = new FormData()

        for (let key in orderBody) {
            // Convierte los datos del formulario en un FormData, necesario si se quiere subir archivos
            formData.append(key, orderBody[key])
        }
        // Asignar el archivo para el envío
        if (files.length > 0) {

            for (const file of addFiles) {
                formData.append('files_upload', file)
            }
        }

        // Agrega Status
        formData.append('order_status', statusId[0].id)

        // Agrega Reviewers tags
        const allReviewers = reviewerTags.items.map(item => item.content)

        formData.append('reviewers', allReviewers.join('|'))

        // Agrega Supplierss tags
        formData.append('supplier_mails', suppliersTags.join('|'))

        // Recupera el tipo de orden/ordenes seleccionados y se asignan a un array para enviar datos a M2M
        formData.append('order_type', JSON.stringify(selectedTypesData))

        // Enviar al reducer para crear order
        if (Object.keys(errors).length === 0) addNewOrder(formData)

    }


    // Al recibir archivo en Dropzone
    const onDrop = newFile => setAddFiles(newFile)

    // Flie Drang & Drop
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});

    let files = addFiles.map(file => { console.log('FILE', file)
        return(
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <IconButton
                color="secondary"
                aria-label="upload picture"
                component="span"
                onClick={() => removeFile(file)}
            >
                <Delete color="secondary" />
            </IconButton>
        </li>
    )})

    const removeFile = (fileRemove) => {
        setAddFiles(acceptedFiles.filter( item => (
            item.path !== fileRemove.path && item.size !== fileRemove.size && item.type !== fileRemove.type
        )))
    }

    return (

        <Grid
            container

            justify="center"
        >
            <Grid item xs={12} sm={10} lg={8}>

                <Typography variant="h1">Create New Order</Typography>

                <form
                    method="POST"
                    encType="multipart/form-data"
                    onSubmit={handleSubmit}
                >
                    <Grid
                        container
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} style={{width: '100%'}}>
                            <TextField
                                name="description"
                                label="Description"
                                className={classes.w100}
                                onChange={handleChange}
                                value={orderBody.description}
                            />

                            <FormGroup row>
                                <div className={classes.w100}>
                                    {

                                        (!!order_type) && order_type.map(({id, type_title}) => {
                                            return (
                                                <FormControlLabel
                                                    key={id}
                                                    control={
                                                        <Checkbox
                                                            value={orderBody[type_title]}
                                                            onChange={handleChange}
                                                            name={type_title}
                                                        />
                                                    }
                                                    label={type_title}
                                                />
                                            )
                                        })
                                    }
                                </div>

                                {/* Muestra las medidas de los types seleccionados */}
                                <Grid container>
                                    {
                                        selectedTypes.length > 0 && selectedTypes.map(item => {

                                            return (
                                                <InputWidthHeight
                                                    key={item.id}
                                                    item={item}
                                                    selectedTypesData={selectedTypesData}
                                                    setSelectedTypesData={setSelectedTypesData}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                            </FormGroup>

                            <TextField
                                name="specs"
                                label="Specs"
                                className={classes.w100}
                                onChange={handleChange}
                                value={orderBody.specs}
                                multiline
                                rowsMax={6}
                            />

                            <section style={{marginTop: '15px'}}>
                                <FormLabel component="legend">Upload adittional files / Info</FormLabel>
                                <div {...getRootProps({className: 'dropzone'})}>
                                    <input
                                        {...getInputProps()}
                                    />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                                <aside>
                                    <ul>
                                        {files}
                                    </ul>
                                </aside>
                            </section>

                            <TextField
                                name="comments"
                                label="Comments"
                                className={classes.w100}
                                onChange={handleChange}
                                value={orderBody.comments}
                            />

                            <Grid container>
                                <Grid item xs={12}>

                                    <TextField
                                        name="reviewers-tags"
                                        label="Reviewers / Checkpoints"
                                        className={classes.w100}
                                        // onChange={changeReviewers}
                                        onKeyDown={keyDownReviewers}
                                        // value={reviewersDd}
                                    />

                                    {(!!errors && !!errors.reviewers) &&
                                    <small className="red">{errors.reviewers}</small>}
                                </Grid>

                                <Grid item xs={12} style={{padding: 15, display: 'flex', justifyContent: 'center'}}>
                                    <CustomDD state={reviewerTags} setState={setReviewersTags} />
                                </Grid>

                            </Grid>
                            <ChipInput
                                label="Destination of final materials / Suppliers mails"
                                className={classes.w100}
                                value={suppliersTags}
                                onAdd={(chip) => handleAddChip(chip, true)}
                                onDelete={(chip, index) => handleDeleteChip(chip, index, true)}
                            />
                            {(!!errors && !!errors.supplier_mails) &&
                            <small className="red">{errors.supplier_mails}</small>}


                            <FormControl component="fieldset" style={{marginTop: '20px', width: '100%'}}>
                                <FormLabel component="legend">Order urgency</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="Order urgency"
                                    name="order_urgency"
                                    value={orderBody.order_urgency}
                                    onChange={handleChange}
                                >
                                    {
                                        (!!order_urgency) && order_urgency.map(({id, urgency_title}) => {
                                            return (
                                                <FormControlLabel
                                                    key={id}
                                                    value={id}
                                                    control={<Radio/>}
                                                    label={urgency_title}
                                                />

                                            )
                                        })
                                    }


                                </RadioGroup>
                            </FormControl>

                            {(!!errors && !!errors.order_urgency) &&
                            <small className="red">{errors.order_urgency}</small>}

                            <div className={classes.textEnd}>
                                <Link to={DASHBOARD_LINK} style={{marginRight: '5px'}}>
                                    <Button variant="outlined" color="secondary">Cancel</Button>
                                </Link>

                                <Button type="submit" variant="outlined" color="primary">Ready to order</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>


            </Grid>
        </Grid>


    )

}

export default NewOrder
