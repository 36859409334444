import React, {useContext, useEffect, useState} from 'react';

// Material
import {Button, makeStyles, TextField} from "@material-ui/core";

// Context
import OrderCommentsContext from 'context/orderComments/orderCommentsContext';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        '& > *': {
            marginTop: 5
        }
    }
}))

export default function NewComment({userId, orderId}) {

    const classes = useStyles()

    // Context
    const orderCommentsContext = useContext(OrderCommentsContext)
    const { newComment } = orderCommentsContext

    // State del formulario
    const [ bodyData, setBodyData ] = useState({
        comment_text: '',
    })

    useEffect( () => {
        setBodyData(prevState => {
            return {
                ...prevState,
                order_id: orderId,
                user_comment: userId
            }
        })
    }, [orderId, userId])

    const handleChange = ({ target }) => {
        setBodyData({
            ...bodyData,
            [target.name]: target.value
        })
    }


    const handleSubmit = evt => {
        evt.preventDefault()
        newComment(bodyData)
        setBodyData(prevState => {
            return {
                ...bodyData,
                comment_text: ''
            }
        })
    }

    return (

        <form
            noValidate
            className={classes.form}
        >

            <TextField
                label="Write a comment"
                name="comment_text"
                value={bodyData.comment_text}
                multiline
                variant="outlined"
                rows={4}
                onChange={handleChange}
                style={{width: '100%'}}
            />

            <Button
                variant="outlined"
                color="secondary"
                onClick={handleSubmit}
            >
                Send Comment
            </Button>
        </form>

    )
}
