import {useContext, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    DialogContentText
} from "@material-ui/core";

// Constext
import BaseContext from 'context/base/baseContext'

// Custom Services
import apiPostData from "../../services/apiPostData";

// Constants
import { API_UPLOAD_LINK_FILE } from "types/apiConstants";


export default function SendFileLink({ setOpenFileLink, orderId }) {

    const [ open, setOpen ] = useState(true)

    const [ link, setLink ] = useState({
        file_link: '',
        order: orderId
    })

    const baseContext = useContext(BaseContext)
    const { headers } = baseContext


    const sendLink = async () => {
        const data = await apiPostData(API_UPLOAD_LINK_FILE, link, headers)
        return !!(data.id)
    }

    const handleChange = ({ target }) => {
        setLink({
            ...link,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        const sended = sendLink()
        if (sended) {
            console.log('enviado correctamente, redireccionar con SWAL')
        }else {
        console.log('SWAL error, close')}
    }


    const handleClose = () => {
        setOpen(false)
        setOpenFileLink(false)
    };

    return (

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Send Link</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Paste a link to the file. If you was uploaded a file manually.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    name="file_link"
                    label="Link"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Send Link
                </Button>
            </DialogActions>
        </Dialog>

    )
}