import React from 'react';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Hidden} from "@material-ui/core";

// Custom Components
import TrAdminTableOrder from './TrAdminTableOrder';


const useStyles = makeStyles({
    table: {
        minWidth: '50%',
    },

});


const TableOrderAdmin = ( { itemData } ) => {

    const classes = useStyles()

    // Recuperar datos de usuario o recibirlos
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="order status table">

                <TableHead>
                    <TableRow>
                        {/*<TableCell>Id</TableCell>*/}
                        <TableCell>Description</TableCell>
                        <Hidden smDown>
                            <TableCell>Customer</TableCell>
                        </Hidden>
                        <Hidden mdDown>
                            <TableCell>Assigned to</TableCell>
                        </Hidden>
                        <TableCell>Status</TableCell>
                        <Hidden xsDown>
                            <TableCell>Urgency</TableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>

                <TableBody>

                {
                    (!!itemData) && itemData.map( item => (

                        <TrAdminTableOrder key={item.id} item={item} />
                        
                    ))
                }

                </TableBody>

            </Table>
      </TableContainer>
    )
}

export default TableOrderAdmin
