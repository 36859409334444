import React, {useState, useContext, useEffect} from 'react';

// Router
import {Redirect} from 'react-router-dom';

// Material
import {
    Card,
    CardContent,
    Grid,
} from "@material-ui/core";

// custom Styles
import useLoginStyles from './LoginScreeStyles'

// Context
import AuthContext from 'context/auth/authContext';

// Custom Components
import Spinner from 'components/shared/Spinner'

// Context
import BaseContext from 'context/base/baseContext';

// Constants
import {BASE_LINK} from "types/linkConstants";
import LoginError from "../../components/errors/LoginError";


const LoginScreen = () => {

    const classes = useLoginStyles()

    const baseContext = useContext(BaseContext)
    const {spinner, setSpinner} = baseContext

    const authContext = useContext(AuthContext);
    const {authenticated, errors, token, checkToken, loginDeleteErrors, userLogin} = authContext;

    const [bodyForm, setBodyForm] = useState({
        'username': '',
        'password': ''
    })

    useEffect(() => {
        loginDeleteErrors()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        (token !== null)
            ? checkToken()
            : setSpinner(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleChange = ({target}) => {
        !!errors[target.name] && delete errors[target.name]
        !!errors.credentials && delete errors.credentials

        setBodyForm({
            ...bodyForm,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        userLogin(bodyForm)
    }

    // Si está autenticado redireccionar a BASE_PATH
    if (spinner) return <Spinner/>
    if (authenticated) return <Redirect to={BASE_LINK}/>

    return (
        <div className={classes.root}>
            <main className={classes.main}>
                <Grid item xs={12} sm={10} md={8} lg={6} >
                    <Card className={classes.cardRoot}>
                        <Grid container>

                            <Grid item xs={12} sm={4} justify='center' style={{display: 'flex'}}>
                                <img
                                    src="/assets/img/Logo_Tracer.jpg"
                                    alt="Logo Tracer Audiovisual"
                                />
                            </Grid>

                            <Grid item xs={12} sm={8} justify="center" alignItems="center">
                                <div className={classes.details}>
                                    <CardContent className={classes.w100}>

                                        <LoginError errors={[errors]}/>

                                        <form className={classes.form} noValidate>


                                            <input
                                                type="email"
                                                placeholder="Email"
                                                name="username"
                                                value={bodyForm.username}
                                                onChange={handleChange}
                                                className={classes.tracerLoginInput}
                                            />

                                            <input
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                value={bodyForm.password}
                                                onChange={handleChange}
                                                className={classes.tracerLoginInput}
                                            />

                                            <button
                                                type="submit"
                                                className={classes.tracerBtnLogin}
                                                onClick={handleSubmit}
                                                value="Login"
                                            >
                                                Login
                                            </button>
                                        </form>
                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>

                </Grid>

            </main>
        </div>

    )
}

export default LoginScreen
