import React, { useReducer, useContext } from 'react';

// Context
import BaseContext from 'context/base/baseContext'
import OrdersContext from 'context/orders/ordersContext';
import FileReviewContext from './fileReviewContext';

// Reducer
import FileReviewReducer from './fileReviewReducer';

// Constants
import {
    API_ORDER_FILE_REVIEW
} from 'types/apiConstants';

import {
    ADD_REVIEW_FILE,
    DISABLE_REVIEW_FILE
} from 'types/types';

const FileReviewState = ({children}) => {

    // Context
    const baseContext = useContext(BaseContext)
    const { headersFiles, headers } = baseContext

    const ordersContext = useContext(OrdersContext)
    const { orderAssigned } = ordersContext

    // Status inicial
    const initialState = {
        reviewFiles: [],
        errors: []
    }

    // Reducer
    const [ state, dispatch ] = useReducer(FileReviewReducer, initialState)

    // Desactiva el último fichero de revirew
    const disableLastFile = ( fileId ) => {

        const URL = `${API_ORDER_FILE_REVIEW}${fileId}/`
        const bodyData = {
            actived: false
        }
console.log('LastFile')
        fetch(URL, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(bodyData)
        })
            .then( response => response.json() )
            .then( data => {console.log('DATA', data)
                if (data.id) {
                    dispatch({
                        type: DISABLE_REVIEW_FILE
                    })
                }else {
                    console.log('PatchFileReview, error raro')
                }
            } )
            .catch( e => console.log('PatchFileReview ', e) )
    }

    // Envía Archivo de review
    const orderSendReviewFile = (bodyData, order_status, order_id, lastFile) => {

        if (!!lastFile) disableLastFile(lastFile)

        // Enviar los datos a la api
        fetch(API_ORDER_FILE_REVIEW, {
            headers: headersFiles,
            method: 'POST',
            body: bodyData
        })
            .then( response => response.json() )
            .then( data => {
                if (data.data) {
                    // Enviar datos Success
                    dispatch({
                        type: ADD_REVIEW_FILE,
                        payload: data.data
                    })

                    // Actualiza el status de la order a ReadyToReview
                    orderAssigned(order_id, {order_status})
                }


            })
            .catch( e => console.log('Review file ', e) )

        // comprobar errores y mostrarlos

        // si todo es correcto actualizar el status a ReadyToReview(order_status)
    }

    return (
        <FileReviewContext.Provider
            value={{
                reviewFiles: state.reviewFiles,
                errors: state.errors,
                orderSendReviewFile
            }}
        >
            {children}
        </FileReviewContext.Provider>
    )
}

export default FileReviewState
