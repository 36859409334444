import React from 'react';

// Router
import { Redirect, Route } from "react-router-dom";

// Pages
import DashboardScreen from "pages/Dashboard/DashboardScreen";
import NewCustomer from "pages/Customers/NewCustomer";
import CustomersList from "pages/Customers/CustomersList";
import NewOperator from "pages/Operators/NewOperator";
import OperatorsList from "pages/Operators/OperatorsList";
import NewOrder from "pages/Orders/NewOrder";
import OrderStatus from "pages/Orders/OrdersStatus";
import OrderDetail from "pages/Orders/OrderDetail";

// Constants
import { ORDERS_MENU } from "../types/menuConstants";
import {
    BASE_LINK, CREATE_ORDER_LINK,
    DASHBOARD_LINK, DETAIL_ORDER_LINK,
    OPERATOR_CREATE_LINK, OPERATORS_LIST_LINK,
    USERS_CREATE_LINK,
    USERS_LIST_LINK
} from "types/linkConstants";


const BaseRoutes = () => {
    return (
        <>
            <Route exact path={DASHBOARD_LINK}>
                <DashboardScreen />
            </Route>

            <Route exact path={BASE_LINK}>
                <Redirect to={DASHBOARD_LINK}/>
            </Route>

            <Route path={USERS_CREATE_LINK}>
                <NewCustomer />
            </Route>
            <Route path={USERS_LIST_LINK}>
                <CustomersList />
            </Route>

            <Route path={OPERATOR_CREATE_LINK}>
                <NewOperator />
            </Route>

            <Route path={OPERATORS_LIST_LINK}>
                <OperatorsList />
            </Route>

            <Route path={CREATE_ORDER_LINK}>
                <NewOrder />
            </Route>

            {
                ORDERS_MENU.map(({itemLink, itemSlug}) => (
                    <Route
                        key={itemSlug}
                        path={itemLink}
                        component={OrderStatus}
                    />

                ))
            }

            <Route path={DETAIL_ORDER_LINK}>
                <OrderDetail />
            </Route>
        </>
    )
}

export default BaseRoutes