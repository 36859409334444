import React from 'react';

// Custom Layout
import TracerAppBar from 'components/layout/TracerAppBar';


const HeaderMain = () => {

    return (
       <>
            <TracerAppBar />

       </>
    )
}

export default HeaderMain
