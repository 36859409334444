import Konva from "konva";
import { v1 as uuidv1 } from 'uuid'

export default function addTextNode(stage, layer, color, text, xInit, yInit) {
  const id = uuidv1();
  const textNode = new Konva.Text({
    text: text,
    x: xInit,
    y: yInit,
    fontSize: 20,
    draggable: true,
    width: 200,
    fill: color,
    id,
  })

  layer.add(textNode)
  layer.draw()

  return id
}