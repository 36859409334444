import React, { useContext } from 'react';

// Router
import { useLocation } from 'react-router-dom';

// context
import BaseContext from 'context/base/baseContext';

// Custom Components
import DashboardAdminComponent from 'components/dashboard/DashboardAdminComponent';
import DashboardCustomerComponent from 'components/dashboard/DashboardCustomerComponent';
import Spinner from 'components/shared/Spinner';

// Constants
import {DASHBOARD_LINK} from 'types/linkConstants';


const DashboardScreen = () => {
    const location = useLocation()

    const baseContext = useContext(BaseContext)
    const { userData } = baseContext

    const isAdmin = (!!userData) && (userData.is_superuser || userData.is_staff)

    if (!!userData && Object.keys(userData).length === 0) return <Spinner />

    return (

        (isAdmin && location.pathname === DASHBOARD_LINK)
        ?
            <DashboardAdminComponent />
        :
            <DashboardCustomerComponent />

    )

}

export default DashboardScreen
