import React, { useContext } from 'react';

// Contexts
import BaseContext from "context/base/baseContext";
import OrdersContext from 'context/orders/ordersContext';

// Custom Components
import Reviewers from "components/reviewers/Reviewers";
import ButtonAssign from './ButtonAssign';
import SendReviewFile from '../SendReviewFile';
import ShowOrderLink from '../ShowOrderLink';

import OrderSentSelect from "../OrderSent/OrderSentSelect";

// Constants
import {
    ORDER_STATUS
} from 'types/orderStatus';



const OrderDetailChangeStatus = ( { operator } ) => {

    // Componente a renderizar
    let renderData = null

    // contexts
    const baseContext = useContext(BaseContext);
    const { userData } = baseContext

    const ordersContext = useContext(OrdersContext)
    const { orderAssigned, orderSelected } = ordersContext

    if (userData.is_superuser || userData.is_staff) {

        if (userData.operator !== null) {
            switch (parseInt(orderSelected.status_id)) {
                case ORDER_STATUS.new_order:
                    renderData = <ButtonAssign
                        id={orderSelected.id}
                        orderAssigned={orderAssigned}
                        operator={userData.operator}
                        order_status={ORDER_STATUS.in_process}
                    />
                    break;

                case ORDER_STATUS.in_process:
                    if (parseInt(userData.operator) === operator) {
                        renderData = <SendReviewFile
                            order_id={orderSelected.id}
                            order_status={ORDER_STATUS.ready_review}
                        />
                        return renderData
                    }

                    break;

                case ORDER_STATUS.ready_review:
                    renderData = <Reviewers id={orderSelected.id} />
                    break;

                case ORDER_STATUS.approved:
                    // renderData = <ButtonChangeStatus
                    //     id={orderSelected.id}
                    //     title='Approved'
                    //     orderAssigned={orderAssigned}
                    //     order_status={ORDER_STATUS.sent}
                    // />
                    renderData = <OrderSentSelect
                        orderId={orderSelected.id}
                    />
                    break;
                
                case ORDER_STATUS.sent:
                case ORDER_STATUS.archive:
                    renderData = <ShowOrderLink
                        fileLink={orderSelected.file_link}
                    />
                    break;

                default:
                    renderData = null
                    break;
            }
        }else {
            return <h3>Eres admin pero no operator</h3>
        }

    }

    return (
        <>
            {renderData}
        </>
    )
}

export default OrderDetailChangeStatus
