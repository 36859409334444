import React from "react";

// Material
import {
    Grid,
    Typography
} from "@material-ui/core";


export default function DataLayout({ orderSelected, classes }) {

    const {
        specs, supplier_mails, urgency_name
    } = orderSelected

    return (
        <Grid item xs={12} lg={6} className={classes.gridPadding}>
            <Typography variant="h3" className={classes.mb10}>Order data:</Typography>

            <Typography variant="h4">
                Specs: <Typography variant="body2">{specs}</Typography>
            </Typography>
            <Typography variant="h4">
                Comments: <Typography variant="body2">{specs}</Typography>
            </Typography>
            <Typography variant="h4">
                Supplier mails: <Typography variant="body2">{supplier_mails}</Typography>
            </Typography>
            <Typography variant="h4">
                Urgency: <Typography variant="body2">{urgency_name}</Typography>
            </Typography>
        </Grid>
    )
}
