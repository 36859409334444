import {useRef, useEffect, useState} from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

export default function ImageShape({ imageUrl }) {
    const shapeRef = useRef()
    const [image] = useImage(imageUrl, 'Anonymous')

    const [ imagePx, setImagePx ] = useState({
        width: 0,
        height: 0
    })

    useEffect(() => {
        (!!image) && imageToCanvas()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    const imageToCanvas = () => {

        // Ajusta la imagen al canvas conservando el ratio

        const width = window.innerWidth * 0.9
        const height = window.innerHeight - 250

        const imageWidth = image.naturalWidth
        const imageHeight = image.naturalHeight
        const imageRatio = imageWidth / imageHeight

        let newWidth = imageWidth
        let newHeight = imageHeight

        if (image.naturalWidth > width ) {
            newWidth = width
            newHeight = width / imageRatio
        }

        if (newHeight > height) {
            newHeight = height
            newWidth = newHeight * imageRatio
        }

        setImagePx({
            width: newWidth,
            height: newHeight
        })

    }

    return (
        <Image
            image={image}
            ref={shapeRef}
            width={imagePx.width}
            height={imagePx.height}
        />
    )

}