import React, {useState, useEffect, useContext} from 'react';

// Router
import { useParams } from "react-router-dom";

// Material
import {Button, TextField} from "@material-ui/core";
import {API_CHECK_REVIEWERS} from "../../types/apiConstants";
import BaseContext from "../../context/base/baseContext";


const LandingReviewerScreen = () => {

    const params = useParams()

    // Context para las headers
    const baseContext = useContext(BaseContext)
    const { headers } = baseContext

    // Estado para comprobar el code, Si viene en argumento asignarlo a code
    const [ newCode, setNewCode ] = useState(
        (!!params.code) ? params.code : ''
    )

    // Estado para gestionar errores
    const [ errors, setErrors ] = useState({
        error: false,
        type: ''
    })

    // Estado para el formulario
    const [ bodyForm, setBodyForm ] = useState({
        code: ''
    })

    // Efecto que comprueba el código cada vez que se reciba o se haga submit
    useEffect( () => {
        // Comprobar si el codigo es correcto y redireccionar o retornar error
        (!!newCode) && checkCode()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCode])

    const handleChange = ( { target } ) => {
        // Limpia errores si los hay
        (errors.error) && setErrors({
            error: false,
            type: ''
        })

        setBodyForm({
            ...bodyForm,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setNewCode(bodyForm.code)
    }

    const checkCode = () => {

        const body = (!!params.code) ? JSON.stringify({ code: newCode}) : JSON.stringify(bodyForm)

        fetch(API_CHECK_REVIEWERS, {
            headers: headers,
            method: 'POST',
            body: body
        })
            .then( response => response.json() )
            .then( data => {
                console.log(data)
                if (data.data) {
                    console.log(data)
                    // Guardar el token en localstorage

                    // Redireccionar a la página que recoge los datos
                    // history.push(LANDING_CHANGE_LINK)
                }else {
                    // Los errores vienen en 'msg', si no lo simulamos
                    setErrors({
                        error: true,
                        type: (!!data.msg) ? data.msg : 'Invalid or Expired Token...'
                    })
                }
            } )
            .catch( e => console.log('Check ', e) )

    }

    return (
        <>

            <h3>Please paste your code below</h3>
            <form
                method="POST"
                encType="multipart/form-data"
                className="w-100 d-flex flex-column"
                onSubmit={handleSubmit}
            >
                <TextField
                    name="code"
                    label="Code"
                    onChange={handleChange}
                    value={bodyForm.code}
                />
                { errors.error && errors.type }

                <Button type="submit" variant="outlined" color="primary" >Check code</Button>
            </form>
        </>
    )
}

export default LandingReviewerScreen