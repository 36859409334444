// Material
import { Grid, Typography } from '@material-ui/core';

// Custom Components
import OrderLink from './OrderLink';

export default function ShowOrderLink( { fileLink } ) {

    return (
        <Grid container>
            <Typography variant="h3">Order Link</Typography>

            <Grid item xs={12}>
            {
                (!!fileLink) &&
                    (fileLink.length > 0)
                        ? fileLink.map( item => <OrderLink key={item.id} orderLink={item} />)
                        : <spam>Generating Link, wait few minutes and back again.</spam>
            }
            </Grid>
        </Grid>
    
    )
}