import { makeStyles } from "@material-ui/core";

const useLoginStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    main: {
        width: '100%',
        minHeight: '85vh',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        flex: '1 0 auto',
    },
    cardRoot: {
        display: 'flex',
        width: '100%',
        boxShadow: 'none'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
    },

    w100: {
        width: '100%'
    },
    tracerBtnLogin: {
        width: '100%',
        height: 30,
        border: '1px solid black',
        borderRadius: 50,
        backgroundColor: '#e11e1d',
        color: 'white',
        textTransform: 'uppercase',
        '&:hover': {
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: 'rgba(225, 30, 29, .8)'
        }
    },
    tracerLoginInput: {
        width: '100%',
        border: '3px solid transparent',
        borderRadius: 50,
        padding: '5px 0',
        backgroundColor: '#000000',
        color: 'white',
        textAlign: 'center',
        marginBottom: 10
    }
}));

export default useLoginStyles
