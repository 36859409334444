import React from 'react';

// Router
import { Link } from 'react-router-dom';

// Material
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Chip, Hidden} from '@material-ui/core';

// Constants
import { DETAIL_ORDER_NAME, ORDERS_LINK } from 'types/linkConstants';
import { COLOR_ORDER_STATUS, TITLE_ORDER_STATUS } from 'types/orderStatus';
import { ORDERS_MENU } from 'types/menuConstants';


const TrAdminTableOrder = ( { item } ) => {

    const status = ORDERS_MENU[item.order_status -1]
    const statusTitle = TITLE_ORDER_STATUS[status.itemSlug]
    const colorStatus = COLOR_ORDER_STATUS[status.itemSlug]

    return (
        <TableRow key={item.id}>
            {/*<TableCell component="th" scope="row">*/}
            {/*    {item.id}*/}
            {/*</TableCell>*/}
            <TableCell>
                <Link to={`${ORDERS_LINK}${item.id}${DETAIL_ORDER_NAME}`}>
                    {item.description}
                </Link>
            </TableCell>
            <Hidden smDown>
                <TableCell>
                        {item.customer_name}
                </TableCell>
            </Hidden>
            <Hidden mdDown>
                <TableCell>
                        {item.operator_name}
                </TableCell>
            </Hidden>
            <TableCell>
                <Link to={`${status.itemLink}`}>
                    <Chip
                        style={colorStatus}
                        variant="outlined"
                        label={statusTitle}
                        size="small"
                        clickable />
                </Link>
            </TableCell>
            <Hidden xsDown>
                <TableCell>
                    {item.urgency_name}
                </TableCell>
            </Hidden>
        </TableRow>
    )
}

export default TrAdminTableOrder
