import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {DETAIL_ORDER_NAME, ORDERS_LINK} from "../../types/linkConstants";
import IconButton from "@material-ui/core/IconButton";
import {Delete} from "@material-ui/icons";

const TrTableUsers = ( { id, name, email, link = null } ) => {

    const handleClick = () => {
        if (!!link) {
            const url = `${link.url}${id}/`
            link.action(url, id)
        }
    }

    // Recuperar datos de usuario
    return (
        <TableRow key={id}>
            <TableCell component="th" scope="row">
                {id}
            </TableCell>
            <TableCell>
                <Link to={`${ORDERS_LINK}${name}${DETAIL_ORDER_NAME}`}>
                    {name}
                </Link>
            </TableCell>
            <TableCell>
                {email}
            </TableCell>
            {
                (!!link) &&
                    <TableCell>
                        <IconButton
                            color="secondary"
                            aria-label="upload picture"
                            component="span"
                            size="small"
                            onClick={handleClick}
                        >
                            <Delete color="secondary"/>
                        </IconButton>
                    </TableCell>
            }
        </TableRow>
        // <tr>
        //
        //     <th scope="row" >{id}</th>
        //     <td><a href="#!">{name}</a></td>
        //     <td><a href="#!">{email}</a></td>
        //
        // </tr>
    )
}

export default TrTableUsers
