import React from 'react';

// Material
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';


const ButtonAssign = ( { orderAssigned, operator, id, order_status } ) => {

    const handleAssign = e => {
        e.preventDefault()

        const bodyData = {
            operator,
            order_status
        }

        // Asignamos operator y cambiamos status de la order
        orderAssigned( id, bodyData )
    }
    
    return (

        <Button 
            color="secondary"
            onClick={handleAssign}
        >
            Assign <DoneIcon />
        </Button>

    )
}

export default ButtonAssign
