import React, {useContext, useEffect} from 'react';

// Context
import BaseContext from 'context/base/baseContext';

// Custom Components
import HeaderMain from 'components/shared/HeaderMain';
import TracerDrawer from "components/layout/TracerDrawer";


const AppRouters = () => {

    // Context
    const baseContext = useContext(BaseContext);
    const { getUserData } = baseContext

    useEffect( () => {
        getUserData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <>
            <header>
                <HeaderMain />
            </header>

            <TracerDrawer />

        </>

    )
}

export default AppRouters
