import React, {useContext, useReducer} from 'react';

// Router
import {useHistory} from 'react-router-dom';

// SweetAlert
import swal from "sweetalert";

// Context
import BaseContext from 'context/base/baseContext';
import OrdersContext from 'context/orders/ordersContext';

// Reducer
import OrdersReducer from 'context/orders/ordersReducer';

// Constants
import {
    API_NEW_ORDER_DATA,
    API_ORDERS,
} from 'types/apiConstants';

import {
    VALIDATION_ERRORS,
    DELETE_ERRORS,
    LIST_ORDERS,
    NEW_ORDER,
    MODIFY_ORDER,
    GET_ORDER_STATUS,
    ORDER_DATA,
    ORDER_SELECTED
} from 'types/types';

import {BASE_LINK} from 'types/linkConstants';

import {ALERT_NEW_ORDER} from "types/alertTypes";
import {ORDER_STATUS_REVERSE, TITLE_ORDER_STATUS} from "../../types/orderStatus";
import {EMAIL_TYPE} from "../../types/emailsTypes";
import apiGetData from "../../services/apiGetData";
import apiPatchData from "../../services/apiPatchData";

const OrdersState = props => {

    // History para enviar cuando se crea la order
    const history = useHistory()

    const baseContext = useContext(BaseContext);
    const {headers, headersFiles, sendEmail} = baseContext

    const initialState = {
        orders: [],
        orderSelected: {},
        pageOrders: [],
        orderData: {},
        errors: {}
    }

    const [state, dispatch] = useReducer(OrdersReducer, initialState)

    // Crea una nueva orden
    const addNewOrder = async (formData) => {

        fetch( API_ORDERS, {
            headers: headersFiles,
            method: 'POST',
            body: formData
        })
            .then( response => response.json())
            .then( data => { console.log(data)
                if (data.error) {
                    dispatch({
                        type: VALIDATION_ERRORS,
                        payload: data.error
                    })
                }else if(data.detail) {
                    console.log('NewOrder Error: ', data.detail)
                }else {
                    dispatch({
                        type: NEW_ORDER,
                        payload: data
                    })

                    // Enviar email (EMAIL_TYPE.new_order, extraData{orderName, customer})
                    const extraData = {
                        order_name: data.description,
                        customer: data.customer_id
                    }
                    sendEmail(EMAIL_TYPE.new_order, extraData)

                    // Alerta
                    swal( ALERT_NEW_ORDER ).then( () => history.push(BASE_LINK) )

                }
                //setHeaderContent()
            })
            .catch(e => console.log('NewOrder:', e))
    }

    // Recupera todas las órdenes
    const getOrders = async () => {
        const data = await apiGetData(API_ORDERS, headers)
        dispatch({
            type: LIST_ORDERS,
            payload: data
        })

    }

    const getOrderData = async () => {
        const data = await apiGetData(API_NEW_ORDER_DATA, headers)
        dispatch({
            type: ORDER_DATA,
            payload: data
        })

    }

    // Recupera las orders de la página actual
    const getPageOrders = page => {
        const data = state.orders.filter(order => order.order_status === page)

        dispatch({
            type: GET_ORDER_STATUS,
            payload: data
        })

    }

    const selectOrder = orderId => {
        const data = state.orders.filter(order => order.id === parseInt(orderId))

        if (data.length > 0) {
            dispatch({
                type: ORDER_SELECTED,
                payload: data[0]
            })
        }
    }


    // Asigna operador a orden
    const orderAssigned = async (id, bodyData) => {

        const API_MODIFY_ORDER = `${API_ORDERS}${id}/`

        const data = await apiPatchData(API_MODIFY_ORDER, bodyData, headers)

        // Si vienen errores van dentro de msg:
        if (data.msg) {
            dispatch({
                type: VALIDATION_ERRORS,
                payload: data.msg
            })
        } else {
            // Recuperando el nombre del nuevo estado
            const action = ORDER_STATUS_REVERSE[bodyData.order_status]
            const actionText = TITLE_ORDER_STATUS[action]

            // La data modificada viene como objeto y no dentro de data:
            dispatch({
                type: MODIFY_ORDER,
                payload: data
            })

            // Mostrar alerta
            swal({
                title: 'Change Order Status',
                text: `Status Successfully Changed to... ${actionText}`,
                icon: 'success'
            }).then(() => history.push(BASE_LINK))


        }
    }


    // Borra los errores de los campos
    const deleteErrors = () => {
        dispatch({
            type: DELETE_ERRORS,
        })
    }

    const setErrors = error => {
        dispatch({
            type: VALIDATION_ERRORS,
            payload: error
        })
    }

    return (
        <OrdersContext.Provider
            value={{
                orders: state.orders,
                orderSelected: state.orderSelected,
                pageOrders: state.pageOrders,
                orderData: state.orderData,
                errors: state.errors,
                deleteErrors,
                getOrders,
                addNewOrder,
                orderAssigned,
                getOrderData,
                getPageOrders,
                selectOrder,
                setErrors
            }}
        >
            {props.children}
        </OrdersContext.Provider>
    )
}

    export default OrdersState
