import {
    GET_COMMENTS,
    POST_COMMENT
} from "types/types";

export default function OrderCommentsReducer( state, action ) {
    switch (action.type) {
        case GET_COMMENTS:
            return {
                ...state,
                orderComments: action.payload
            }

        case POST_COMMENT:
            return {
                ...state,
                orderComments: [
                    action.payload,
                    ...state.orderComments
                ]
            }

        default:
            return this.state
    }
}
