import React, {useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

// Context
import AuthContext from 'context/auth/authContext'
import BaseContext from 'context/base/baseContext';

// Custom Components
import Spinner from 'components/shared/Spinner';

// Constants
import { LOGIN_LINK } from 'types/linkConstants';


const PrivateRouters = ( { children, ...rest } ) => {

    const baseContext = useContext(BaseContext)
    const { spinner } = baseContext

    const authContext = useContext(AuthContext)
    const { authenticated } = authContext

    if (spinner ) return <Spinner />
    return (
        <Route
            {...rest}
            render={({ location }) =>
                authenticated 
                    ? 
                        children
                    :
                        <Redirect
                            to={{
                                pathname: LOGIN_LINK,
                                state: { from: location }
                            }}
                        />
                
            }
        />
    )
}

export default PrivateRouters
