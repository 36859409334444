import {ACCEPT_REJECT, GET_REVIEWERS, NEXT_REVIEWER, GET_REJECTED} from "types/types";


const ReviewersReducer = ( state, action ) => {
    switch (action.type) {

        case ACCEPT_REJECT:
            return {
                ...state,
                reviewers:
                    action.payload
                ,
            }

        case GET_REJECTED:
            return {
                ...state,
                rejected: action.payload
            }

        case GET_REVIEWERS:
            return {
                ...state,
                reviewers: [
                    ...action.payload.review_data
                ],
                fileReview: action.payload.file_review
            }

        case NEXT_REVIEWER:
            return {
                ...state,
                next: action.payload
            }

        default:
            return state
    }
}

export default ReviewersReducer