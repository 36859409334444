// export const API_URL = 'http://192.168.0.111:8000'
export const API_URL = 'https://api.traceraudiovisual.com'
const API_VERSION = '/api/v1'
const API_BASE = `${API_URL}${API_VERSION}`

// Account Urls
const ACCOUNT = '/account'
export const API_LOGIN = `${API_BASE}${ACCOUNT}/token/`
export const API_TOKEN_VERIFY = `${API_LOGIN}verify/`
export const API_USER_DATA = `${API_BASE}${ACCOUNT}/user/`

// Customers Urls
export const API_CUSTOMERS = `${API_BASE}/customers/`
export const API_CUSTOMERS_GET_ADMINS = `${API_BASE}/customers/?admins=true`

// Operators
export const API_OPERATORS = `${API_BASE}/operators/`

// Reviewers
// export const API_REVIEWERS = `${API_BASE}/reviewers/`

// Reviewers FileReviewers
export const API_REVIEWERS_FILE_REVIEWERS = `${API_BASE}/reviewers_file_reviewers/`

// Landing Reviewers
export const API_CHECK_REVIEWERS = `${API_BASE}/check/`

// Orders Urls
export const API_NEW_ORDER_DATA = `${API_BASE}/new_order_data/`
export const API_ORDERS = `${API_BASE}/orders/`
export const API_ORDER_FILE_REVIEW = `${API_BASE}/file_review/`

// Order comments
export const API_ORDER_COMMENTS = `${API_BASE}/order_comment/`

// Send Email
export const API_EMAIL_SEND = `${API_BASE}/send_email/`

// Upload TempFile (Order Sent)
export const API_UPLOAD_TEMP_FILE = `${API_BASE}/temp_file/`

// Link to file (Order Sent
export const API_UPLOAD_LINK_FILE = `${API_BASE}/file_link/`
