import {Dialog, TextField} from "@material-ui/core";

export default function TextModal({ text, setText, textModal, setTextModal, handleKeyDown }) {

    const handleOnChange = ({target}) => setText(target.value)

    const handleClose = () => setTextModal(false)

    return (
        <Dialog
            open={textModal}
            onClose={handleClose}
        >
            <TextField
                autoFocus
                placeholder="Write Text"
                value={text}
                style={{padding: 10}}
                onChange={ handleOnChange }
                onKeyDown={ handleKeyDown }
            />
        </Dialog>
    )
}