import {
    LIST_CUSTOMERS,
    ADMINS_CUSTOMERS,
    NEW_CUSTOMER,
    CUSTOMER_DELETE_ERRORS,
    CUSTOMER_ERRORS, DELETE_CUSTOMER
} from 'types/types';

const CustomerReducer = (state, action) => {
    switch( action.type ) {

        case DELETE_CUSTOMER:
        case NEW_CUSTOMER:
        case ADMINS_CUSTOMERS:
        case LIST_CUSTOMERS:

            return {
                ...state,
                customers: action.payload
            }

        case CUSTOMER_DELETE_ERRORS:
            return {
                ...state,
                errors: {} 
            }

        case CUSTOMER_ERRORS:
            return {
                ...state,
                errors: action.payload
            }

        default:
            return state
    }
}

export default CustomerReducer