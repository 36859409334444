import { useContext, useEffect } from 'react';

// Routers
import PrivateRouters from 'routes/PrivateRouters';
import AppRouters from 'routes/AppRouters';

// Context
import BaseContext from 'context/base/baseContext';
import AuthContext from "context/auth/authContext";


const HomeScreen = () => {

    const baseContext = useContext(BaseContext)
    const { setSpinner } = baseContext

    const authContext = useContext(AuthContext);
    const { token, authenticated, checkToken } = authContext;

    useEffect( () => {
        (authenticated || token !== null)
            ? checkToken()
            : setSpinner(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        { 
            <PrivateRouters>
                <AppRouters />
            </PrivateRouters>  
        }
        </>
        
    )
}

export default HomeScreen