import {
    ADD_REVIEW_FILE,
    DISABLE_REVIEW_FILE
} from 'types/types';

const FileReviewReducer = (state, action) => {
    switch (action.type) {
        case ADD_REVIEW_FILE:
            return {
                ...state,
                reviewFiles: [
                    ...state.reviewFiles,
                    action.payload
                ]
            }

        case DISABLE_REVIEW_FILE:
            return {
                ...state,
                reviewFiles: []
            }

        default:
            return state
    }
}

export default FileReviewReducer