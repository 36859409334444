import React from "react";

// Material
import {
    Grid,
    makeStyles
} from "@material-ui/core";

// Custom Components
import InfoUploadLayout from "./InfoUploadLayout";
import TypesMeasuresLayout from "./TypesMeasuresLayout";
import DataLayout from "./DataLayout";

const useStyles = makeStyles( (theme) => ({
    mb10: {
        marginBottom: 10
    },
    gridPadding: {
        padding: 10,

    }
}))

export default function OrderDataLayout({ orderSelected }) {

    const classes = useStyles()

    const {
        info_upload, order_type
    } = orderSelected

    return (

        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Grid container>
                <DataLayout orderSelected={orderSelected} classes={classes}/>

                <TypesMeasuresLayout order_type={order_type} classes={classes}/>

                <InfoUploadLayout info_upload={info_upload} classes={classes}/>
            </Grid>
        </Grid>
    )
}
