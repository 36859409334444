import React, { useState, useEffect } from 'react';

// Material
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        height: 25,
        marginBottom: 15
    },
    alert: {
        backgroundColor: 'rgb(250, 179, 174)',
        border: '1px solid rgb(117, 17, 12)',
        padding: 5,
        color: 'rgb(117, 17, 12)',
        textAlign: 'center',
        borderRadius: 5
    }
}))


const LoginError = ({errors}) => {
    const classes = useStyles()
    const error = errors[0]

    const [ showError, setShowError ] = useState({
        error: false,
        text: ''
    })

    useEffect( () => {
        if (!!error.credentials) {
            setShowError({
                error: true,
                text: error.credentials
            })
        }else if(!!error.username && !!error.password) {
            setShowError({
                error: true,
                text: 'Username and Password may not be blank'
            })
        }else if (!!error.username || !!error.password) {
            const errorKey = Object.keys(error)[0]
            const errorText = `${capitalize(errorKey)}: ${error[errorKey]}`
            console.log(Object.keys(error)[0])
            setShowError({
                error: true,
                text: errorText
            })
        }else {
            setShowError({
                error: false,
                text: ''
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors] )


    // Controla si se ven o no los errores
    const hidden = (showError.error) ? {visibility: 'visible'} : {visibility: 'hidden'}

    const capitalize = text => text[0].toUpperCase() + text.slice(1)

    return (
        <div className={classes.container} style={hidden}>
            {/*<Alert severity="error">This is an error alert — check it out!</Alert>*/}
            <div className={classes.alert} >
                {/*{!!error && error}*/}{showError.text}
            </div>
        </div>
    )
}

export default LoginError