import React from 'react';
import TrTableUsers from './TrTableUsers';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    table: {
        minWidth: '50%',
    },
});

const TableUsers = ( { itemData, text, link } ) => {
    const classes = useStyles()

    // Recuperar datos de usuario o recibirlos
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="order status table">
                <TableHead>

                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>{text}</TableCell>
                        <TableCell>email</TableCell>

                    </TableRow>

                </TableHead>
                <TableBody>

                    {
                        (itemData.length > 0) &&
                            itemData.map(item => (
                                <TrTableUsers
                                    key={item.id}
                                    id={item.id}
                                    email={item.user_email}
                                    name={item.name}
                                    link={link}
                                />
                            ))
                    }

                </TableBody>
            </Table>
        </TableContainer>
        // <table className="table w-100">
        //     <thead>
        //         {/* Comprobar si es un listado de Operators o de Customers */}
        //         <tr>
        //             <th scope="col">Id</th>
        //             <th scope="col">Operator/Customer</th>
        //             <th scope="col">email</th>
        //
        //             {/* Comprobar usuario y location */}
        //         </tr>
        //     </thead>
        //     <tbody>
        //
        //             {
        //                 (itemData.length > 0) &&
        //                     itemData.map( item => <TrTableUsers
        //                                             key={item.id}
        //                                             id={item.id}
        //                                             email={item.email}
        //                                             name={item.name}
        //                                           /> )
        //             }
        //
        //
        //
        //     </tbody>
        // </table>
    )
}

export default TableUsers
