import lineShape from './LineShape';
import RectangleShape from './RectangleShape';
import CircleShape from './CircleShape';
import TextShape from './TextShape';
import NewImage from "./ImageShape";

export const addLine = lineShape
export const NewRectangle = RectangleShape
export const NewCircle = CircleShape
export const addTextNode = TextShape
export const ImageShape = NewImage