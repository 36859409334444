import React, { useEffect, useState, useContext } from 'react';

// Material
import {Grid, FormControl, FormHelperText, makeStyles, NativeSelect, TextField, Button} from '@material-ui/core';

// Context
import BaseContext from 'context/base/baseContext';
import CustomerContext from 'context/customers/customerContext';


const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: 'column'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    boton: {
        marginTop: 15
    }
}));

const NewCustomer = () => {

    const classes = useStyles()

    const baseContext = useContext(BaseContext);
    const { userData } = baseContext

    const customerContext = useContext(CustomerContext);
    const { customers, errors, getCustomersAdmins, addNewCustomer } = customerContext

    const [ newCustomer, setNewCustomer ] = useState({
        email: '',
        password: '',
        name: '',
        description: '',
        customer: '',
        admin: false
    })

    
    useEffect( () => {
        getCustomersAdmins()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData] )

    const handleChange = ({target}) => {
        console.log(target.name, target.value)
        setNewCustomer({
            ...newCustomer,
            [target.name]: target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!newCustomer.customer && (userData.is_staff || userData.is_superuser)) newCustomer.admin = true

        addNewCustomer(newCustomer)
        // TODO: Redireccionar despues de crear usuario
        console.log('USUARIO CREADO')
    }

    return (

        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{width: '100%'}}
        >
            <Grid item xs={12} sm={10} lg={8} >
                <form method="POST" action="" className={classes.form}>


                    {
                        (!!userData && userData.is_staff) && customers.length > 0 &&
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                value={newCustomer.customer}
                                onChange={handleChange}
                                name="customer"
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'customer' }}
                            >
                                <option value="">---- New Customer Admin ---</option>
                                {customers.map(({id, name}) => (
                                    <option key={id} value={id}>{name}</option>
                                ))}

                            </NativeSelect>
                            <FormHelperText>Keep new to create admin or select admin.</FormHelperText>
                        </FormControl>



                            // <select name="customer" onChange={handleChange} defaultValue={newCustomer.customer} >
                            //     <option value="">---- New Customer Admin ---</option>
                            //     {customers.map(({id, name}) => {
                            //         return (
                            //             <SelectOption
                            //                 key={id}
                            //                 valueId={id}
                            //                 valueCode={name}
                            //             />
                            //         )
                            //     })}
                            // </select>
                    }


                    <TextField
                        name="email"
                        label="Email"
                        type="email"
                        onChange={handleChange}
                        value={newCustomer.email}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Email"*/}
                    {/*    name="email"*/}
                    {/*    value={newCustomer.email}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.email) && <small className="red">{errors.email}</small> }

                    <TextField
                        name="password"
                        label="Password"
                        type="password"
                        onChange={handleChange}
                        value={newCustomer.password}
                    />
                    {/*<input*/}
                    {/*    type="password"*/}
                    {/*    placeholder="Password"*/}
                    {/*    name="password"*/}
                    {/*    value={newCustomer.password}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.password) && <small className="red">{errors.password}</small> }

                    <TextField
                        name="name"
                        label="Name"
                        onChange={handleChange}
                        value={newCustomer.name}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Name"*/}
                    {/*    name="name"*/}
                    {/*    value={newCustomer.name}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}
                    { (!!errors && !!errors.name) && <small className="red">{errors.name}</small> }

                    <TextField
                        name="description"
                        label="Description"
                        onChange={handleChange}
                        value={newCustomer.description}
                    />
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Description"*/}
                    {/*    name="description"*/}
                    {/*    value={newCustomer.description}*/}
                    {/*    onChange={handleChange}*/}
                    {/*/>*/}

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.boton}
                    >
                        Create
                    </Button>
                    {/*<input*/}
                    {/*    type="submit"*/}
                    {/*    value="Create"*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*/>*/}
                </form>
            </Grid>
        </Grid>

    )
}

export default NewCustomer
