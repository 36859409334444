export const BASE_LINK = '/';

export const LOGIN_LINK = `${BASE_LINK}login`;
export const DASHBOARD_LINK = `${BASE_LINK}dashboard`;

export const ORDERS_LINK = `${DASHBOARD_LINK}/orders/`;
export const CREATE_ORDER_LINK = `${ORDERS_LINK}create`;
export const DETAIL_ORDER_NAME = `/detail/`;
export const DETAIL_ORDER_LINK = `${ORDERS_LINK}:id${DETAIL_ORDER_NAME}`;


export const NEW_ORDERS_LINK = `${ORDERS_LINK}new_order`;
export const ORDERS_IN_PROCESS_LINK = `${ORDERS_LINK}in_process`;
export const READY_TO_REVIEW_LINK = `${ORDERS_LINK}ready_review`;
export const ORDERS_APPROVED_LINK = `${ORDERS_LINK}approved`;
export const ORDERS_SENT_LINK = `${ORDERS_LINK}sent`;
export const ORDERS_ARCHIVE_LINK = `${ORDERS_LINK}archive`;

// Users
export const USERS_LIST_LINK = `${DASHBOARD_LINK}/list-users`;
export const USERS_CREATE_LINK = `${DASHBOARD_LINK}/user-create`;

// Operators
export const OPERATORS_LIST_LINK = `${DASHBOARD_LINK}/list-operators`;
export const OPERATOR_CREATE_LINK = `${DASHBOARD_LINK}/operator-create`;

// Landing New Reviewers
export const LANDING_CHECK_LINK = `${BASE_LINK}landing_check/`;
export const LANDING_CHECK_LINK_PARAMS = `${BASE_LINK}landing_check/:code`;
export const LANDING_CHANGE_LINK = `${BASE_LINK}landing_change/`;



