import {
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    LOGIN_TOKEN,
    LOGIN_DELETE_ERRORS,
    LOGOUT
} from 'types/types';

const AuthReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_EXITOSO:

            return {
                ...state,
                token: action.payload.access,
                authenticated: true
            }

        case LOGIN_ERROR:
            return {
                ...state,
                authenticated: false,
                errors: action.payload,
            }

        case LOGIN_DELETE_ERRORS:
            return {
                ...state,
                errors: {},
                loading: false
            }

        case LOGIN_TOKEN:
            return {
                ...state,
                authenticated: true
            }

        case LOGOUT:
            return {
                ...state,
                ...action.payload, loading: false
            }

        default:
            return state
    }
}

export default AuthReducer