import React, { useReducer} from 'react';

import BaseContext from 'context/base/baseContext';
import BaseReducer from 'context/base/baseReducer';

import {
    API_EMAIL_SEND,
    API_USER_DATA
} from 'types/apiConstants';

import {
    GET_USER_DATA,
    SET_HEADER_TOKEN,
    REMOVE_HEADER_TOKEN,
    SET_SPINNER,
    SET_TITLE
} from 'types/types'
import apiGetData from "../../services/apiGetData";
import apiPostData from "../../services/apiPostData";

const BaseState = props => {

    const initialState = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': ``
        },
        headersContent: {
            'Content-Type': 'application/json',
        },
        headersFiles: {
            'Authorization': ``
        },
        spinner: true,
        title: '',
        userData: {}
    }

    const [ state, dispatch ] = useReducer(BaseReducer, initialState)

    const setTitle = title => {
        document.title = `Tracer Audiovisual | ${title}`
        dispatch({
            type: SET_TITLE,
            payload: title
        })
    }

    const getUserData = async () => {

        const data = await apiGetData(API_USER_DATA, state.headers)
        dispatch({
            type: GET_USER_DATA,
            payload: data[0]
        })

        // fetch( API_USER_DATA, {
        //     headers: {...state.headers},
        //     method: 'GET',
        // })
        //     .then( response => response.json() )
        //     .then( data => {
        //         dispatch({
        //             type: GET_USER_DATA,
        //             payload: data[0]
        //         })
        //         // getOrderData()
        //     })
        //     .catch( e => console.log('GetuserData ', e))

    }

    const setHeaderToken = ( item ) => {
        dispatch({
            type: SET_HEADER_TOKEN,
            payload: item
        })
        setSpinner(false)
    }

    const removeHeaderToken = () => {
        dispatch({
            type: REMOVE_HEADER_TOKEN
        })
    }

    const sendEmail = async ( email_type, email_data, emails=null ) => {

        const bodyData = {emails, email_type, email_data}

        const data = await apiPostData(API_EMAIL_SEND, bodyData, state.headers)
        console.log(data)
        // fetch(API_EMAIL_SEND, {
        //     headers: state.headers,
        //     method: 'POST',
        //     body: JSON.stringify(bodyData)
        // })
        //     .then( response => response.json() )
        //     .then( data => console.log(data) )
        //     .catch( e => console.log('send_email ', e ))

    }

    const setSpinner = addSpinner => {
        dispatch({
            type: SET_SPINNER,
            payload: addSpinner
        })
    }

    return (
        <BaseContext.Provider
            value={{
                headers: state.headers,
                headersContent: state.headersContent,
                headersFiles: state.headersFiles,
                spinner: state.spinner,
                title: state.title,
                userData: state.userData,
                getUserData,
                removeHeaderToken,
                sendEmail,
                setHeaderToken,
                setSpinner,
                setTitle
            }}
        >
            {props.children}
        </BaseContext.Provider>
    )
}

export default BaseState
